import React, { useState } from "react";
import { Steps } from "antd";

import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import Tagline from "../../components/tagline";

import "./styles.css";

const { Step } = Steps;

const ConsumerStore = () => {
  const [current, setCurrent] = useState(0);

  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [customerDiscount, setCustomerDiscount] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const next = (data) => {
    setCurrent(current + 1);
    updateData(data);
  };

  const nextTwo = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const updateData = (data) => {
    setDataSource(data.dataSource);
    setTotal(data.total);
    setCustomerDiscount(data.customerDiscount);
    setShippingCost(data.shippingCost);
    setTotalPrice(data.totalPrice);
  };

  return (
    <>
      <Steps progressDot current={current}>
        <Step title="Products" />
        <Step title="Shipping Details" />
      </Steps>
      <div className="steps-content">
        {current === 0 ? (
          <StepOne
            next={next}
            dataSource={dataSource}
            total={total}
            shippingCost={shippingCost}
            totalPrice={totalPrice}
          />
        ) : (
          <StepTwo
            next={nextTwo}
            prev={prev}
            stepOneData={{
              dataSource,
              total,
              customerDiscount,
              shippingCost,
              totalPrice,
            }}
          />
        )}
      </div>
      <Tagline displayText hideNumber={true} />
    </>
  );
};

export default ConsumerStore;
