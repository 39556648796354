import React from "react";
import { Table } from "antd";

import axios from "../../config/api";
import Tagline from "../../components/tagline";
import { baseUrl } from "../../config/constants";

export default class SalesSheets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const token = localStorage.getItem("token");

    try {
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios({
        method: "get",
        url: "/sheet/list",
        headers,
      });

      if (response.data.salesSheets) {
        this.setState({ dataSource: response.data.salesSheets });
      }
    } catch (e) {
      console.log("Error loading salessheets!");
    }
    this.setState({ loading: false });
  }

  render() {
    const columns = [
      {
        dataIndex: "name",
        render: (text, row) => (
          <a
            href={`${baseUrl}${row.path}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.fileName}
          </a>
        ),
        title: "Document name",
      },
    ];

    return (
      <>
        <h4>Sales sheets</h4>
        <Table
          dataSource={this.state.dataSource}
          columns={columns}
          pagination={false}
          loading={this.state.loading}
          scroll={{ x: true }}
        />
        <br></br>
        <Tagline />
      </>
    );
  }
}
