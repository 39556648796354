import React from "react";

const PurchaseOrderSuccess = () => {
  return (
    <>
      <h4>Success!</h4>
      <br />
      <br />
      <p>
        You have selected your products. Your Purchase Order will arrive within
        a few minutes.
      </p>
    </>
  );
};

export default PurchaseOrderSuccess;
