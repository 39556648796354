import React from "react";
import { Row, Col, Button, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import FileSaver from "file-saver";

import axios from "../../../config/api";
import { notificationConfig } from "../../../config/constants";

function StoresTab() {
  const [loading, setLoading] = React.useState(false);
  const handleUserExport = async () => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await axios({
        method: "get",
        url: "/users/list30days",
        headers,
      });

      if (response.statusText === "OK") {
        const users = (response.data.users || []).map((user) => {
          return {
            "Store ID Number": user.id,
            "Store Name": user.storeName,
            "Company Name": user.companyName,
            "Contact Name": `${user.primaryContactFirstName} ${user.primaryContactLastName}`,
            "Address Information": user.address,
            City: user.city,
            State: user.stateOrProvince,
            "Zip Code": user.zipCode,
            Email: user.email,
            Phone: user.primaryContactPhone,
            "Preferred Payment Method":
              user.preferredPaymentMethod === "creditCard"
                ? "Credit/Debit Card"
                : // : user.preferredPaymentMethod === "digitalCheck"
                  // ? "Digital Check"
                  "Paper Check",
            Website: user.website,
            Instagram: user.instagram,
            Title: user.role,
            Type:
              user.storeType === "regionalRetailChain"
                ? "Regional Retail Chain"
                : user.storeType === "localStore"
                ? "Local Store"
                : user.storeType === "nationalRetailChain"
                ? "National Retail Chain"
                : "Other (association, community organization)",
            Description: user.storeDescription,
            Locations: user.howManyLocations,
          };
        });

        const csv = Papa.unparse(users);
        const csvBlob = new Blob([csv], { type: "text/csv;" });
        FileSaver.saveAs(csvBlob, "latestUsers.csv");
      }
    } catch (err) {
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    setLoading(false);
  };

  const handleAllUsersExport = async () => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await axios({
        method: "get",
        url: "/users/listall",
        headers,
      });

      if (response.statusText === "OK") {
        const users = (response.data.users || []).map((user) => {
          return {
            "Store ID Number": user.id,
            "Store Name": user.storeName,
            "Company Name": user.companyName,
            "Contact Name": `${user.primaryContactFirstName} ${user.primaryContactLastName}`,
            "Address Information": user.address,
            City: user.city,
            State: user.stateOrProvince,
            "Zip Code": user.zipCode,
            Email: user.email,
            Phone: user.primaryContactPhone,
            "Preferred Payment Method":
              user.preferredPaymentMethod === "creditCard"
                ? "Credit/Debit Card"
                : // : user.preferredPaymentMethod === "digitalCheck"
                  // ? "Digital Check"
                  "Paper Check",
            Website: user.website,
            Instagram: user.instagram,
            Title: user.role,
            Type:
              user.storeType === "regionalRetailChain"
                ? "Regional Retail Chain"
                : user.storeType === "localStore"
                ? "Local Store"
                : user.storeType === "nationalRetailChain"
                ? "National Retail Chain"
                : "Other (association, community organization)",
            Description: user.storeDescription,
            Locations: user.howManyLocations,
          };
        });

        const csv = Papa.unparse(users);
        const csvBlob = new Blob([csv], { type: "text/csv;" });
        FileSaver.saveAs(csvBlob, "allUsers.csv");
      }
    } catch (err) {
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Row>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <p className="description">*Stores approved in the last 30 days</p>
        </Col>
        <Col xs={{ span: 23, offset: 1 }} lg={{ span: 12, offset: 0 }} />
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <Button
            onClick={handleUserExport}
            style={{
              backgroundColor: "#b32a2a",
              borderColor: "#b32a2a",
              color: "#fff",
              float: "left",
            }}
            loading={loading}
          >
            Export Latest Users*
          </Button>
        </Col>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 4, offset: 7 }}>
          <Button
            onClick={handleAllUsersExport}
            style={{
              backgroundColor: "#b32a2a",
              borderColor: "#b32a2a",
              color: "#fff",
            }}
            loading={loading}
          >
            Export All Users
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default StoresTab;
