import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import { Layout, Button } from "antd";

import HeaderComponent from "./components/header";
import UserLogin from "./pages/login";
import UserSignup from "./pages/signup";
import SalesSheets from "./pages/salesSheets";
import OrderForm from "./pages/order";
import OrderList from "./pages/orderList";
import Dashboard from "./pages/dashboard";
import OrderSuccess from "./pages/orderSuccess";
import PurchaseOrderSuccess from "./pages/purchaseOrderSuccess";
import { ForgotPassword, ResetPassword } from "./pages/password";
import Home from "./pages/home";
import CustomerStore from "./pages/customer";
import logo from "./assets/logo-border.png";
import star from "./assets/logo-star.png";
import TaglineWhite from "./components/taglineWhite";
import axios from "./config/api";

const { Sider, Content } = Layout;

/* private route template, props current user from confirmed token */
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const user = localStorage.getItem("user");
      return isAuthenticated() ? (
        <Component {...props} user={JSON.parse(user)} />
      ) : (
        <Redirect to="/login" />
      );
    }}
  />
);

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const user = localStorage.getItem("user");
      return JSON.parse?.(user)?.isAdmin === true ? (
        <Component {...props} user={JSON.parse(user)} />
      ) : (
        <Redirect to="/" />
      );
    }}
  />
);

const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  if (token && token !== "" && token !== undefined) {
    return true;
  }

  localStorage.clear();
  return false;
};

export default class PublicRoutes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      user: localStorage.getItem("user"),
    };
  }

  componentDidMount() {
    this.setState({ location: window.location.pathname });
    window.addEventListener("popstate", this.eventFunction);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.eventFunction);
  }

  handleLogout = () => {
    this.setState({ token: null, user: null });
    localStorage.clear();
    axios.defaults.headers.common["Authorization"] = null;
  };

  eventFunction = () => {
    this.setState({ location: window.location.pathname });
  };

  handleLogin = (response) => {
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("user", JSON.stringify(response.data.user));
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.data.token}`;
    this.setState({
      token: response.data.token,
      user: JSON.stringify(response.data.user),
    });
  };

  render() {
    return (
      <Router>
        <div>
          <div className="auth-wrapper">
            <HeaderComponent
              handleLogout={this.handleLogout}
              token={this.state.token}
              user={JSON.parse?.(this.state.user)}
            />
            <Layout>
              <Sider
                breakpoint="lg"
                collapsedWidth="0"
                width="300"
                trigger={null}
                zeroWidthTriggerStyle={{ width: 0, height: 0 }}
                style={{ backgroundColor: "#b32a2a" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={logo}
                    style={{
                      width: 240,
                      marginTop: 125,
                      marginLeft: 20,
                    }}
                    alt="logo"
                  />
                  <img
                    src={star}
                    style={{
                      width: 80,
                      marginTop: 75,
                      marginLeft: 25,
                      // marginRight: 70,
                    }}
                    alt="flag"
                  />
                  <img
                    src={star}
                    style={{
                      width: 120,
                      marginLeft: 55,
                      marginTop: 70,
                    }}
                    alt="flag"
                  />
                </div>
              </Sider>
              <Content
                style={{ backgroundColor: "#b32a2a", overflow: "scroll" }}
              >
                <div className="auth-inner">
                  <Switch>
                    <Route
                      exact
                      path="/login"
                      render={(props) => (
                        <UserLogin {...props} handleLogin={this.handleLogin} />
                      )}
                    />
                    <Route
                      exact
                      path="/signup"
                      render={(props) => (
                        <UserSignup {...props} handleLogin={this.handleLogin} />
                      )}
                    />
                    <Route
                      exact
                      path="/forgotpassword"
                      render={(props) => <ForgotPassword {...props} />}
                    />
                    <Route
                      exact
                      path="/consumer-store"
                      render={(props) => <CustomerStore {...props} />}
                    />
                    <Route
                      exact
                      path="/order/success"
                      render={(props) => <OrderSuccess {...props} />}
                    />
                    <PrivateRoute
                      path="/resetpassword"
                      component={ResetPassword}
                    />
                    <PrivateRoute path="/salessheets" component={SalesSheets} />
                    <PrivateRoute path="/orderform" component={OrderForm} />
                    <PrivateRoute path="/orders" component={OrderList} />
                    <PrivateRoute path="/" exact component={Home} />
                    <PrivateRoute
                      path="/po-success"
                      exact
                      component={PurchaseOrderSuccess}
                    />
                    <AdminRoute path="/dashboard" component={Dashboard} />
                    <Redirect to="/" />
                  </Switch>
                </div>
                {window.location.pathname === "/login" ? (
                  <>
                    {window.innerWidth < 1000 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Link to="/signup">
                          <Button
                            style={{
                              // backgroundColor: "#b32a2a",
                              // borderColor: "#b32a2a",
                              // color: "#fff",
                              marginTop: 25,
                              minHeight: 30,
                              minWidth: 100,
                            }}
                            className="register-button"
                            size="large"
                            onClick={() => {
                              // this.props.history.push("/login");
                              window.dispatchEvent(new Event("popstate"));
                            }}
                          >
                            REGISTER
                          </Button>
                        </Link>
                      </div>
                    )}

                    <TaglineWhite />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={star}
                        style={
                          window.innerWidth < 1000
                            ? {
                                marginTop: 25,
                                width: "40%",
                              }
                            : {
                                height: 120,
                                marginTop: 25,
                                // marginLeft: 25,
                                // marginRight: 70,
                              }
                        }
                        alt="flag"
                      />
                    </div>
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={tagline}
                        style={
                          window.innerWidth < 1000
                            ? { marginTop: 25, marginBottom: 25, width: "90%" }
                            : { height: 25, marginTop: 50 }
                        }
                        alt="tagline"
                      />
                    </div> */}
                  </>
                ) : null}
              </Content>
              <Sider
                breakpoint="lg"
                collapsedWidth="0"
                width="300"
                trigger={null}
                zeroWidthTriggerStyle={{ width: 0, height: 0 }}
                style={{ backgroundColor: "#b32a2a" }}
              >
                {window.location.pathname !== "/login" ? (
                  <div
                    key="not-signup"
                    style={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={star}
                      style={{
                        height: 80,
                        width: 80,
                        marginTop: 75,
                        marginLeft: 25,
                      }}
                      alt="flag"
                    />
                    <img
                      src={star}
                      style={{
                        marginTop: 125,
                        // marginLeft: 15,
                        marginRight: 20,
                        width: 150,
                        height: 150,
                        marginLeft: 50,
                      }}
                      alt="flag"
                    />
                    <img
                      src={star}
                      style={{
                        height: 100,
                        width: 100,
                        marginTop: 125,
                        marginLeft: 55,
                      }}
                      alt="flag"
                    />
                  </div>
                ) : (
                  window.innerWidth > 1000 && (
                    <div
                      key="signup"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Link to="/signup">
                        <Button
                          style={{
                            // backgroundColor: "#b32a2a",
                            // borderColor: "#b32a2a",
                            // color: "#fff",
                            marginTop: 150,
                            minHeight: 50,
                            minWidth: 120,
                          }}
                          className="register-button"
                          size="large"
                          onClick={() => {
                            // this.props.history.push("/login");
                            window.dispatchEvent(new Event("popstate"));
                          }}
                        >
                          REGISTER
                        </Button>
                      </Link>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <img
                          src={star}
                          style={
                            window.innerWidth < 1000
                              ? {
                                  marginTop: 25,
                                  width: "40%",
                                  marginRight: 20,
                                }
                              : {
                                  marginTop: 25,
                                  // marginLeft: 15,
                                  marginRight: 20,
                                  width: 150,
                                  height: 150,
                                }
                          }
                          alt="flag"
                        />

                        <img
                          src={star}
                          style={{
                            marginTop: 75,
                            // marginLeft: 15,
                            marginRight: 10,
                            width: 120,
                            height: 120,
                          }}
                          alt="flag"
                        />
                      </div>
                    </div>
                  )
                )}
              </Sider>
            </Layout>
          </div>
        </div>
      </Router>
    );
  }
}
