import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

import Tagline from "../../components/tagline";
export default class Home extends React.Component {
  render() {
    return (
      <>
        <br></br>

        <br></br>
        <h4>Welcome to the ACUSA Wholesale Store Portal! </h4>
        <br />
        <p>
          We are focused on facilitating an easy, smart way for you to connect
          with us, our product information and ordering process. We are
          committed to excellent service rivaled only by our quality product
          line.
        </p>
        <div
          style={{
            marginTop: 15,
            marginBottom: 15,
            borderTopColor: "#b32a2a",
            borderTopWidth: 1,
            borderTopStyle: "solid",
            height: 5,
          }}
        />
        <p>
          <Link to="/salessheets">Here</Link> you can download documentation
          About Us, our Product Sheets and special announcements.
        </p>
        <Link to="/salessheets">
          <Button
            style={{
              backgroundColor: "#b32a2a",
              borderColor: "#b32a2a",
              color: "#fff",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            Product Sales Sheets
          </Button>
        </Link>
        <div
          style={{
            marginTop: 15,
            marginBottom: 15,
            borderTopColor: "#b32a2a",
            borderTopWidth: 1,
            borderTopStyle: "solid",
            height: 5,
          }}
        />
        <p>
          <Link to="/orderform">Here</Link> you can review our product/price
          list, select products and create a Purchase Order. Your contact
          information will automatically populate the Purchase Order from your
          profile created when you registered. You can add a separate shipping
          address, if needed, and write special shipping instructions. When you
          select your products and tap Submit, a Purchase Order will be created
          and emailed to you and our accounting department. Payment instructions
          will be enclosed with your Purchase Order.
        </p>

        <Link to="/orderform">
          <Button
            style={{
              backgroundColor: "#b32a2a",
              borderColor: "#b32a2a",
              color: "#fff",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            Place an order
          </Button>
        </Link>

        <div
          style={{
            marginTop: 15,
            marginBottom: 15,
            borderTopColor: "#b32a2a",
            borderTopWidth: 1,
            borderTopStyle: "solid",
            height: 5,
          }}
        />

        <p>
          <Link to={"/orders"}>Here</Link> you can review your current Purchase
          Order(s) and make payments
        </p>
        <Link to="/orders">
          <Button
            style={{
              backgroundColor: "#b32a2a",
              borderColor: "#b32a2a",
              color: "#fff",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            Make Payment
          </Button>
        </Link>

        <div
          style={{
            marginTop: 15,
            marginBottom: 15,
            borderTopColor: "#b32a2a",
            borderTopWidth: 1,
            borderTopStyle: "solid",
            height: 5,
          }}
        />

        <Tagline displayText />
      </>
    );
  }
}
