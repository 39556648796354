import React from "react";
import { Form, Input, Button, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import axios from "../../config/api";
import { notificationConfig } from "../../config/constants";

const FormItem = Form.Item;

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };
  }

  handleValidatePassword = (rule, value, callback) => {
    if (!value) callback("Please input your password!");
    if (value.length < 6) callback("Password should be at least 6 characters");
    else {
      callback();
    }
  };

  handlePasswordRepeat = (rule, value, callback) => {
    if (!value) callback("Please repeat your password");
    if (value && value !== this.state.newPassword) {
      callback("Passwords do not match.");
    } else {
      callback();
    }
  };

  onValuesChange = (value) => {
    this.setState(value);
  };

  onFinish = async (data) => {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    try {
      const response = await axios({
        method: "post",
        url: "/changepassword",
        data,
        headers,
      });
      if (response.statusText === "OK") {
        this.props.history.push("/");
        notification.open({
          message: "Success!",
          description: "Your password was changed!",
          icon: <ExclamationCircleOutlined />,
          style: notificationConfig.style,
        });
      }
    } catch (e) {
      notification.open({
        message: "Error!",
        description:
          "We've encountered an error. Please check your password and try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    return (
      <Form
        {...layout}
        name="resetPasswordForm"
        initialValues={{ remember: true }}
        onValuesChange={this.onValuesChange}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
      >
        <label>Current Password</label>
        <FormItem
          name="oldPassword"
          initialValue=""
          rules={[
            {
              required: true,
              validator: this.handleValidatePassword,
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input.Password placeholder={"Current Password"} />
        </FormItem>

        <label>New Password</label>
        <FormItem
          name="newPassword"
          initialValue=""
          rules={[
            {
              required: true,
              validator: this.handleValidatePassword,
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input.Password placeholder={"New Password"} />
        </FormItem>

        <label>Confirm New Password</label>
        <FormItem
          name="confirmNewPassword"
          initialValue=""
          rules={[
            {
              required: true,
              validator: this.handlePasswordRepeat,
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input.Password placeholder={"Confirm New Password"} />
        </FormItem>

        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: "#b32a2a",
              borderColor: "#b32a2a",
            }}
          >
            Submit
          </Button>
        </FormItem>
      </Form>
    );
  }
}
