import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/logo-star.png";
import "./style.css";

export default class Header extends React.Component {
  render() {
    return (
      <Navbar
        collapseOnSelect
        expand="sm"
        bg="light"
        variant="light"
        fixed="top"
      >
        <Navbar.Brand>
          {this.props.token ? (
            <Link
              to={"/"}
              onClick={() => window.dispatchEvent(new Event("popstate"))}
            >
              <img
                src={logo}
                alt="Logo"
                style={{
                  height: 20,
                  marginBottom: -13,
                  marginTop: -16,
                  marginLeft: -16,
                  marginRight: 5,
                }}
              />
            </Link>
          ) : (
            <img
              src={logo}
              alt="Logo"
              style={{
                height: 20,
                marginBottom: -13,
                marginTop: -16,
                marginLeft: -16,
                marginRight: 5,
              }}
            />
          )}
          {this.props.token ? (
            <Link
              className="navbar-brand navbar-custom"
              to={"/"}
              onClick={() => window.dispatchEvent(new Event("popstate"))}
            >
              Angel Chimes USA Wholesale Portal
            </Link>
          ) : (
            <div className="navbar-brand navbar-custom">Angel Chimes USA</div>
          )}
        </Navbar.Brand>
        {this.props.token && (
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        )}
        {
          this.props.token ? (
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                {/* <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link> */}
              </Nav>
              <Nav>
                {/* // uncomment when deploy */}
                {this.props.user?.isAdmin === true && (
                  <Link className="nav-link" to="/dashboard">
                    Dashboard
                  </Link>
                )}
                <Link className="nav-link" to="/">
                  Home
                </Link>
                <Link className="nav-link" to="/orderform">
                  Order
                </Link>
                <Link className="nav-link" to="/orders">
                  Make Payment
                </Link>
                <Link className="nav-link" to="/salessheets">
                  Sales sheets
                </Link>
                <Link className="nav-link" to="/resetpassword">
                  Reset Password
                </Link>
                <Link
                  className="nav-link"
                  to={"/login"}
                  onClick={this.props.handleLogout}
                >
                  Log out
                </Link>
              </Nav>
            </Navbar.Collapse>
          ) : null
          // <Navbar.Collapse id="responsive-navbar-nav">
          //   <Nav className="mr-auto">
          //     {/* <Nav.Link href="#features">Features</Nav.Link>
          //   <Nav.Link href="#pricing">Pricing</Nav.Link> */}
          //   </Nav>
          //   <Nav>
          //     <Link className="nav-link" to="/consumer-store">
          //       Store
          //     </Link>
          //     <Link className="nav-link" to="/login">
          //       Log In
          //     </Link>
          //     <Link className="nav-link" to="/signup">
          //       Sign Up
          //     </Link>
          //   </Nav>
          // </Navbar.Collapse>
        }
      </Navbar>
    );
  }
}
