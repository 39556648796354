import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  Table,
  Row,
  Col,
  notification,
  Popconfirm,
  Radio,
  Form,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import FileSaver from "file-saver";

import axios from "../../../config/api";
import { notificationConfig } from "../../../config/constants";

const FormItem = Form.Item;

function PurchaseOrdersTab() {
  const [loading, setLoading] = React.useState(false);
  const [storeProducts, setStoreProducts] = React.useState([]);
  const [customerProducts, setCustomerProducts] = React.useState([]);
  const [dataSource, setDataSource] = React.useState([]);
  const [paymentMethod, setPaymentMethod] = React.useState(null);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await axios({
        method: "get",
        url: "/outstandingPurchaseOrders",
        headers,
      });

      if (response.statusText === "OK") {
        setDataSource(response.data.storeOrders);
      }
    } catch (err) {
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }

    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await axios({
        method: "get",
        url: "/products/get",
        headers,
      });

      if (response.statusText === "OK") {
        const a = response.data.store.map((el) => {
          el.sku = el.key;
          return el;
        });
        const b = response.data.customer.map((el) => {
          el.sku = el.key;
          return el;
        });
        setStoreProducts(a);
        setCustomerProducts(b);
        // setStoreDiscount(response.data.storeDiscount);
        // setCustomerDiscount(response.data.customerDiscount);
      }
    } catch (err) {
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    setLoading(false);
  };

  const handleAllStoreSalesExport = async () => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await axios({
        method: "get",
        url: "/allPurchaseOrders",
        headers,
      });

      if (response.statusText === "OK") {
        const purchaseOrders = (response.data.storeOrders || []).map(
          (purchaseOrder) => {
            const skus = {};
            storeProducts.forEach((el) => {
              const found = purchaseOrder.OrderItems.find(
                (e) => e.Product?.key === el.sku
              );
              skus[el.sku] = found ? found.quantity : "";
            });
            return {
              Timestamp: purchaseOrder.createdAt,
              ...skus,
              "Store Name": purchaseOrder.User.storeName,
              "Contact Name": `${purchaseOrder.User.primaryContactFirstName} ${purchaseOrder.User.primaryContactLastName}`,
              "Shipping Address": purchaseOrder.shippingSameAsAbove
                ? "Same"
                : `${purchaseOrder["shippingAddress"]}, ${purchaseOrder["shippingAddress2"]}`,
              "Shipping City": purchaseOrder.shippingSameAsAbove
                ? "Same"
                : `${purchaseOrder["shippingCity"]}`,
              "Shipping State": purchaseOrder.shippingSameAsAbove
                ? "Same"
                : `${purchaseOrder["shippingState"]}`,
              "Shipping ZIP Code": purchaseOrder.shippingSameAsAbove
                ? "Same"
                : `${purchaseOrder["shippingZipCode"]}`,
              "Shipping Instructions": purchaseOrder.specialInstructions,
              Address: `${purchaseOrder.User.address}, ${purchaseOrder.User.address2}`,
              City: `${purchaseOrder.User.city}`,
              State: `${purchaseOrder.User.stateOrProvince}`,
              ZIP: `${purchaseOrder.User.zipCode}`,
              Buyer: purchaseOrder.buyerSameAsAbove
                ? "Same"
                : `${purchaseOrder["buyerFirstName"]}, ${purchaseOrder["buyerLastName"]}, ${purchaseOrder["buyerPhone"]}, ${purchaseOrder["buyerEmail"]}`,
              "Products Total": purchaseOrder.total,
              // "Store Discount": purchaseOrder.resellerDiscount,
              "Total Price": purchaseOrder.totalPrice,
              "Payment Method":
                purchaseOrder.paymentMethod === "creditCard"
                  ? "Credit Card"
                  : purchaseOrder.paymentMethod === "paperCheck"
                  ? "Paper Check"
                  : // : purchaseOrder.paymentMethod === "digitalCheck"
                    // ? "Digital Check"
                    "",
              "Actual Payment Method":
                purchaseOrder.actualPaymentMethod === "creditCard"
                  ? "Credit Card"
                  : purchaseOrder.actualPaymentMethod === "paperCheck"
                  ? "Paper Check"
                  : // : purchaseOrder.actualPaymentMethod === "digitalCheck"
                    // ? "Digital Check"
                    "",
              "Payment Complete": purchaseOrder.paymentComplete ? "Yes" : "No",
              "PO ID": purchaseOrder.id,
              "Store ID Number": purchaseOrder.userId,
              "Company Name": purchaseOrder.User.companyName,
              Email: purchaseOrder.User.email,
            };
          }
        );

        const csv = Papa.unparse(purchaseOrders);
        const csvBlob = new Blob([csv], { type: "text/csv;" });
        FileSaver.saveAs(csvBlob, "store-purchase-orders.csv");
      }
    } catch (err) {
      console.log({ err });
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    setLoading(false);
  };

  const handleAllCustomerSalesExport = async () => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await axios({
        method: "get",
        url: "/allPurchaseOrders",
        headers,
      });

      if (response.statusText === "OK") {
        const purchaseOrders = (response.data.customerOrders || []).map(
          (purchaseOrder) => {
            const skus = {};
            customerProducts.forEach((el) => {
              const found = purchaseOrder.OrderItems.find(
                (e) => e.Product?.key === el.sku
              );
              skus[el.sku] = found ? found.quantity : "";
            });
            return {
              Timestamp: purchaseOrder.createdAt,
              // "Coast to Coast SKUs": purchaseOrder.OrderItems.map(
              //   (item) => `${item.quantity} x ${item.Product.key}`
              // ).join(", "),
              ...skus,
              "Customer Name":
                purchaseOrder.firstName + " " + purchaseOrder.lastName,
              "Shipping Name": purchaseOrder.shippingSameAsAbove
                ? "Same"
                : purchaseOrder.shippingBuyerFirstName +
                  " " +
                  purchaseOrder.shippingBuyerLastName,
              "Shipping Address": purchaseOrder.shippingSameAsAbove
                ? "Same"
                : `${purchaseOrder["shippingAddress"]}`,
              "Shipping City": purchaseOrder.shippingSameAsAbove
                ? "Same"
                : `${purchaseOrder["shippingCity"]}`,
              "Shipping State": purchaseOrder.shippingSameAsAbove
                ? "Same"
                : `${purchaseOrder["shippingState"]}`,
              "Shipping ZIP Code": purchaseOrder.shippingSameAsAbove
                ? "Same"
                : `${purchaseOrder["shippingZipCode"]}`,
              "Shipping Instructions": purchaseOrder.specialInstructions,
              Address: `${purchaseOrder["address"]}`,
              City: `${purchaseOrder["city"]}`,
              State: `${purchaseOrder["state"]}`,
              ZIP: `${purchaseOrder["zipCode"]}`,
              Email: purchaseOrder["email"],
              Phone: purchaseOrder.phone,
              "Products Total": purchaseOrder.total,
              "Total Price": purchaseOrder.totalPrice,
              "Payment Complete": purchaseOrder.paymentComplete ? "Yes" : "No",
              "PO ID": purchaseOrder.id,
            };
          }
        );

        const csv = Papa.unparse(purchaseOrders);
        const csvBlob = new Blob([csv], { type: "text/csv;" });
        FileSaver.saveAs(csvBlob, "consumer-purchase-orders.csv");
      }
    } catch (err) {
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    setLoading(false);
  };

  const handleAllC2CStoreSalesExport = async () => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await axios({
        method: "get",
        url: "/allPurchaseOrdersPaid",
        headers,
      });

      if (response.statusText === "OK") {
        let purchaseOrders = [];

        (response.data.storeOrders || []).forEach((purchaseOrder) => {
          purchaseOrder.OrderItems.forEach((el, index) => {
            purchaseOrders.push({
              "ClientOrder#": purchaseOrder.id,
              "Line#": index + 1,
              "B-LastName": purchaseOrder.User.primaryContactLastName,
              "B-FirstName": purchaseOrder.User.primaryContactFirstName,
              "B-CompanyName": purchaseOrder.User.companyName,
              "B-Address1": purchaseOrder.User.address,
              "B-Address2": purchaseOrder.User.address2,
              "B-City": purchaseOrder.User.city,
              "B-State": purchaseOrder.User.stateOrProvince,
              "B-ZipCode": purchaseOrder.User.zipCode,
              "B-Country": "USA",
              Phone: purchaseOrder.User.primaryContactPhone,
              Email: purchaseOrder.User.email,
              ShipVia: "GRO",
              Date: purchaseOrder.createdAt,
              Product: el.Product.key,
              Qty: el.quantity,
              Price: el.Product.price,
              SLastName:
                purchaseOrder.shippingSameAsAbove &&
                purchaseOrder.buyerSameAsAbove
                  ? ""
                  : purchaseOrder.buyerSameAsAbove
                  ? purchaseOrder.User.lastName
                  : purchaseOrder["buyerLastName"],
              SFirstName:
                purchaseOrder.shippingSameAsAbove &&
                purchaseOrder.buyerSameAsAbove
                  ? ""
                  : purchaseOrder.buyerSameAsAbove
                  ? purchaseOrder.User.firstName
                  : purchaseOrder["buyerFirstName"],
              Scompany: purchaseOrder.shippingSameAsAbove
                ? ""
                : purchaseOrder["shippingName"],
              "S-Address1": purchaseOrder.shippingSameAsAbove
                ? ""
                : purchaseOrder["shippingAddress"],
              "S-Address2": purchaseOrder.shippingSameAsAbove
                ? ""
                : purchaseOrder["shippingAddress2"],
              SCity: purchaseOrder.shippingSameAsAbove
                ? ""
                : purchaseOrder["shippingCity"],
              SState: purchaseOrder.shippingSameAsAbove
                ? ""
                : purchaseOrder["shippingState"],
              SZip: purchaseOrder.shippingSameAsAbove
                ? ""
                : purchaseOrder["shippingZipCode"],
              Scountry: purchaseOrder.shippingSameAsAbove ? "" : "USA",
              SPhone: purchaseOrder.buyerSameAsAbove
                ? purchaseOrder.User.primaryContactPhone
                : purchaseOrder.buyerPhone,
              SEmail: purchaseOrder.buyerSameAsAbove
                ? purchaseOrder.User.email
                : purchaseOrder.buyerEmail,
              "PO#": purchaseOrder.id,
            });
          });
        });

        const csv = Papa.unparse(purchaseOrders);
        const csvBlob = new Blob([csv], { type: "text/csv;" });
        FileSaver.saveAs(
          csvBlob,
          "ANGEL CHIMES WHOLESALE STORE ORDERS FOR C TO C.csv"
        );
      }
    } catch (err) {
      console.log({ err });
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    setLoading(false);
  };

  const handleAllC2CCustomerSalesExport = async () => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await axios({
        method: "get",
        url: "/allPurchaseOrdersPaid",
        headers,
      });

      if (response.statusText === "OK") {
        let purchaseOrders = [];

        (response.data.customerOrders || []).forEach((purchaseOrder) => {
          purchaseOrder.OrderItems.forEach((el, index) => {
            purchaseOrders.push({
              "ClientOrder#": purchaseOrder.id,
              "Line#": index + 1,
              "B-LastName": purchaseOrder.lastName,
              "B-FirstName": purchaseOrder.firstName,
              "B-CompanyName": "",
              "B-Address1": purchaseOrder.address,
              "B-Address2": purchaseOrder.address2,
              "B-City": purchaseOrder.city,
              "B-State": purchaseOrder.state,
              "B-ZipCode": purchaseOrder.zipCode,
              "B-Country": "USA",
              Phone: purchaseOrder.phone,
              Email: purchaseOrder.email,
              ShipVia: "GRO",
              Date: purchaseOrder.createdAt,
              Product: el.Product.key,
              Qty: el.quantity,
              Price: el.Product.price,
              SLastName: purchaseOrder.shippingSameAsAbove
                ? ""
                : purchaseOrder["shippingBuyerLastName"],
              SFirstName: purchaseOrder.shippingSameAsAbove
                ? ""
                : purchaseOrder["shippingBuyerFirstName"],
              Scompany: "",
              "S-Address1": purchaseOrder.shippingSameAsAbove
                ? ""
                : purchaseOrder["shippingAddress"],
              "S-Address2": purchaseOrder.shippingSameAsAbove
                ? ""
                : purchaseOrder["shippingAddress2"],
              SCity: purchaseOrder.shippingSameAsAbove
                ? ""
                : purchaseOrder["shippingCity"],
              SState: purchaseOrder.shippingSameAsAbove
                ? ""
                : purchaseOrder["shippingState"],
              SZip: purchaseOrder.shippingSameAsAbove
                ? ""
                : purchaseOrder["shippingZipCode"],
              Scountry: purchaseOrder.shippingSameAsAbove ? "" : "USA",
              SPhone: purchaseOrder.phone,
              SEmail: purchaseOrder.email,
              "PO#": purchaseOrder.id,
            });
          });
        });

        const csv = Papa.unparse(purchaseOrders);
        const csvBlob = new Blob([csv], { type: "text/csv;" });
        FileSaver.saveAs(
          csvBlob,
          "ANGEL CHIMES CONSUMER STORE ORDERS FOR C TO C.csv"
        );
      }
    } catch (err) {
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    setLoading(false);
  };

  const handleMarkPaymentComplete = async (storeOrderId, index) => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await axios({
        method: "post",
        url: "/markPurchaseOrderPaid",
        headers,
        data: { storeOrderId, paymentMethod },
      });

      if (response.statusText === "OK") {
        getAllData();
      }
    } catch (err) {
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    setPaymentMethod(null);
    setLoading(false);
  };

  const onValuesChange = (values) => {
    setPaymentMethod(values.paymentMethod);
  };

  const columns = [
    {
      dataIndex: "id",
      render: (text, row) => text,
      title: "PO ID",
      sorter: (a, b) => {
        if (a.id > b.id) return -1;
        if (b.id > a.id) return 1;
        return 0;
      },
    },
    {
      dataIndex: "User.storeName",
      render: (text, row) => text,
      title: "Store Name",
    },
    {
      dataIndex: "createdAt",
      render: (text, row) => {
        const date = new Date(text);
        return date.toLocaleDateString("en-US");
      },
      title: "Date",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      dataIndex: "totalPrice",
      render: (text, row) => `$${text}`,
      title: "Amount",
      sorter: (a, b) => a.total - b.total,
    },
    {
      dataIndex: "paymentMethod",
      render: (text, row) =>
        text === "creditCard"
          ? "Credit Card"
          : // : text === "digitalCheck"
            // ? "Digital check"
            "Paper Check",
      title: "Payment Method",
      sorter: (a, b) => a.paymentMethod - b.paymentMethod,
    },
    {
      dataIndex: "paymentComplete",
      render: (text, row, index) => (
        <Popconfirm
          title={
            <>
              <p>
                Are you sure to mark the purchase order {row.id} with the value
                of ${row.totalPrice} as paid?
              </p>
              <label>Payment method</label>
              <br />
              <Form
                name="changePaymentMethodForm"
                initialValues={{ paymentMethod: row.paymentMethod }}
                onValuesChange={onValuesChange}
              >
                <FormItem
                  name="paymentMethod"
                  validateTrigger="onChange"
                  help={
                    "Leave the field unchanged if the payment method is the initially selected one"
                  }
                >
                  <Radio.Group>
                    {/* <Radio.Button value={"digitalCheck"}>
                      Digital Check
                    </Radio.Button> */}
                    <Radio.Button value={"creditCard"}>
                      Debit/Credit Card
                    </Radio.Button>
                    <Radio.Button value={"paperCheck"}>
                      Paper Check
                    </Radio.Button>
                  </Radio.Group>
                </FormItem>
              </Form>
            </>
          }
          onConfirm={() => handleMarkPaymentComplete(row.id, index)}
          okText="Yes"
          cancelText="No"
        >
          <Checkbox checked={text} />
        </Popconfirm>
      ),
      title: "Mark as complete",
      sorter: (a, b) => a.paymentComplete - b.paymentComplete,
    },
  ];

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <Button
            onClick={handleAllStoreSalesExport}
            style={{
              backgroundColor: "#b32a2a",
              borderColor: "#b32a2a",
              color: "#fff",
            }}
            loading={loading}
          >
            Export Wholesale POs
          </Button>
        </Col>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 4, offset: 5 }}>
          <Button
            onClick={handleAllCustomerSalesExport}
            style={{
              backgroundColor: "#b32a2a",
              borderColor: "#b32a2a",
              color: "#fff",
            }}
            loading={loading}
          >
            Export Consumer Sales
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <Button
            onClick={handleAllC2CStoreSalesExport}
            style={{
              backgroundColor: "#b32a2a",
              borderColor: "#b32a2a",
              color: "#fff",
            }}
            loading={loading}
          >
            Export C2C Wholesale POs
          </Button>
        </Col>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 4, offset: 5 }}>
          <Button
            onClick={handleAllC2CCustomerSalesExport}
            style={{
              backgroundColor: "#b32a2a",
              borderColor: "#b32a2a",
              color: "#fff",
            }}
            loading={loading}
          >
            Export C2C Consumer Sales
          </Button>
        </Col>
      </Row>
      <br /> <br />
      <h4>Outstanding Purchase Orders</h4>
      <br />
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={true}
        loading={loading}
        scroll={{ x: true }}
        rowKey="id"
      />
    </>
  );
}

export default PurchaseOrdersTab;
