import React from "react";
import {
  Form,
  Row,
  InputNumber,
  Button,
  notification,
  Popover,
  Col,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import axios from "../../config/api";
import { notificationConfig } from "../../config/constants";

const FormItem = Form.Item;

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

export default class StepOne extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      total: 0,
      shippingCost: 0,
      totalPrice: 0,
      dataSource: [],
      groupedDataSource: {},
    };
  }

  componentDidMount() {
    this.getAllData();
  }

  getAllData = async () => {
    this.setState({ loading: true });
    try {
      const response = await axios({
        method: "get",
        url: "/products/get",
      });

      if (response.statusText === "OK") {
        const newDataSource = this.handleApplyDataSource(
          response.data.customer
        );
        this.setState({
          dataSource: newDataSource,
          groupedDataSource: this.handleGrouping(newDataSource),
          total: this.props.total,
          shippingCost: this.props.shippingCost,
          totalPrice: this.props.totalPrice,
        });
      }
    } catch (err) {
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    this.setState({ loading: false });
  };

  handleApplyDataSource = (array) => {
    if (!this.props.dataSource || this.props.dataSource.length === 0)
      return array;

    return array.map((el) => {
      const found = this.props.dataSource.find((e) => e.productId === el.id);

      return found
        ? {
            ...el,
            quantity: found.quantity,
          }
        : { ...el };
    });
  };

  handleGrouping = (array) => {
    if (!array || array.length === 0) return {};

    let response = {};
    array.forEach((el, index) => {
      response[el.grouping] = Array.isArray(response[el.grouping])
        ? [...response[el.grouping], { ...el, index }]
        : [{ ...el, index }];
    });

    return response;
  };

  onFinish = async (values) => {
    const newState = {
      ...this.state,
      dataSource: this.state.dataSource
        .filter((el) => Number(el.quantity) > 0)
        .map((el) => ({ productId: el.id, quantity: el.quantity })),
    };

    if (Array.isArray(newState.dataSource) && newState.dataSource.length > 0) {
      const data = { ...values, ...newState };
      Object.keys(data).forEach((el) => {
        if (typeof data[el] === "number") {
          data[el] = data[el].toString();
        }
      });

      this.props.next(data);
    } else {
      notification.open({
        message: "Products missing!",
        description: "Please add a quantity for at least one of the products.",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
  };

  onFinishFailed = (errorInfo) => {
    notification.open({
      message: "Information missing!",
      description: "Please fill in all mandatory fields!",
      icon: <ExclamationCircleOutlined />,
      style: notificationConfig.style,
    });
  };

  handleTableChange = (ev, index) => {
    const dataSourceCopy = [...this.state.dataSource];
    dataSourceCopy[index].quantity = ev;

    const shippingCost = dataSourceCopy.reduce(
      (acc, el) => acc + (el.quantity ?? 0) * (Number(el.shippingCost) ?? 0),
      0
    );

    const total = dataSourceCopy.reduce(
      (acc, el) => acc + (el.quantity ?? 0) * (Number(el.price) ?? 0),
      0
    );

    // const singlePacQuantites = dataSourceCopy.reduce(
    //   (acc, el) =>
    //     el.type === "singlePac" ? acc + (el.quantity ?? 0) : acc + 0,
    //   0
    // );

    // shippingCost = singlePacQuantites > 0 && shippingCost + 3;

    this.setState({
      dataSource: dataSourceCopy,
      total,
      totalPrice: this.computeTotalPrice(total, shippingCost),
      shippingCost,
    });
  };

  computeTotalPrice = (total, shippingCost) =>
    (Number(total) + Number(shippingCost)).toFixed(2);

  onValuesChange = (values) => {
    this.setState(values);
  };

  getText = () => <span>Shipping costs</span>;

  getContent = () => (
    <div>
      {this.state.dataSource.map((product) => (
        <p key={product.key}>
          {product.name} - ${product.shippingCost} per unit
        </p>
      ))}
    </div>
  );

  render() {
    return (
      <>
        <h4>Welcome to the Angel Chimes USA Store!</h4>
        <br />
        <br />
        {Object.values(this.state.groupedDataSource).map((rows, indexRow) => (
          <Row
            key={indexRow}
            gutter={[16, 0]}
            style={{
              //   border: "0.5px solid #A2C294",
              borderRadius: 15,
              marginBottom: 30,
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
            }}
          >
            {rows.map((el, index) => (
              <Col
                key={index}
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 24, offset: 0 }}
              >
                <div
                  style={{ display: "flex", width: "100%", marginBottom: 0 }}
                  key={el.id}
                >
                  {index === 0 && (
                    <img
                      src={el.image ? `${el.image}` : ""}
                      alt="product"
                      style={{ height: window.innerWidth < 1000 ? 150 : 220 }}
                    />
                  )}
                  <div style={{ width: "100%", marginTop: 10 }}>
                    <p style={{ marginBottom: 10 }}>
                      <b>{el.name}</b>
                      {!el.available ? (
                        <span style={{ color: "#c82727" }}>*</span>
                      ) : null}
                    </p>
                    <p style={{ marginBottom: 4 }}>
                      {/* <b>
                      {el.type === "singlePac" ? "Single Pac" : "Multi Pac"}
                    </b>{" "} */}
                      ${el.price} per unit{" "}
                      <span style={{ color: "#c82727" }}>
                        {!el.available ? "*Not Available!" : null}
                      </span>
                    </p>
                    <p style={{ marginBottom: 4 }}>{el.description}</p>
                    {/* </Panel> */}
                    {/* </Collapse> */}
                    <InputNumber
                      style={{ alignItems: "flex-end", width: "50%" }}
                      min={0}
                      placeholder="Quantity"
                      onChange={(e) => this.handleTableChange(e, el.index)}
                      value={this.state.dataSource[el.index].quantity}
                      disabled={!el.available}
                    />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        ))}
        <Form
          {...layout}
          name="orderForm"
          initialValues={{ remember: true }}
          onValuesChange={this.onValuesChange}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <br />
          <p style={{ fontSize: 16, color: "#000" }}>
            <b>Order Pricing Summary:</b>
          </p>

          <label style={{ color: "#000" }}>Price (USD)</label>
          <FormItem>
            <InputNumber
              formatter={(value) =>
                `$ ${Number(value) ? Number(value).toFixed(2) : 0.0}`
              }
              disabled
              value={this.state.total}
              precision={2}
              className="input-disabled"
            />
          </FormItem>

          <label style={{ color: "#000" }}>Shipping Cost</label>
          <FormItem>
            <InputNumber
              formatter={(value) => `$ ${value}`}
              disabled
              precision={2}
              value={this.state.shippingCost}
              className="input-disabled"
            />
          </FormItem>

          <Popover
            placement="top"
            title={this.getText}
            content={this.getContent}
            trigger="click"
          >
            {/* <InfoCircleOutlined
                style={{ marginLeft: 10, color: "#b32a2a" }}
              /> */}
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: "#b32a2a",
                borderColor: "#b32a2a",
              }}
            >
              Shipping Costs Details
            </Button>
          </Popover>
          <br />
          <br />

          <label style={{ color: "#000" }}>Total Price (USD)</label>
          <FormItem>
            <InputNumber
              formatter={(value) =>
                `$ ${Number(value) ? Number(value).toFixed(2) : 0.0}`
              }
              disabled
              value={this.state.totalPrice}
              precision={2}
              style={{ width: "30%", minWidth: 88 }}
              className="input-disabled"
            />
          </FormItem>

          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: "#b32a2a", borderColor: "#b32a2a" }}
            >
              Next
            </Button>
          </FormItem>

          <br></br>
        </Form>
      </>
    );
  }
}
