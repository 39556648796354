import React from "react";
import text from "../../assets/tagline.png";

function Tagline(props) {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={text}
          alt="A Symphony of Tradition since 1948"
          style={{
            width: window.innerWidth < 1000 ? "100%" : "50%",
            alignSelf: "center",
            marginTop: 20,
          }}
        />
      </div>

      {props.displayText && (
        <>
          <br></br>
          <div
            style={{
              display: "flex",
              flexDirection:
                window.innerWidth < 1000 ? "flex-column" : "flex-row",
              justifyContent: "center",
            }}
          >
            {props.hideNumber ? null : <div>Contact us at: </div>}
            <div>
              <a
                style={{ marginLeft: 8, marginRight: 8 }}
                href="mailto:partners@angelchimesusa.com"
              >
                {" "}
                Partners@AngelchimesUSA.com{" "}
              </a>
            </div>{" "}
            {props.hideNumber ? null : <div>Kevin Ucarian 201.724.6556</div>}
          </div>
        </>
      )}
    </>
  );
}

export default Tagline;
