import React from "react";
import {
  Form,
  Input,
  Radio,
  Row,
  InputNumber,
  Button,
  notification,
  Popover,
  Select,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import axios from "../../config/api";
import { notificationConfig, action, states } from "../../config/constants";
import Tagline from "../../components/tagline";

import "../customer/styles.css";

const FormItem = Form.Item;
const { Option } = Select;

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

export default class OrderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shippingSameAsAbove: true,
      shippingName: "",
      shippingAddress: "",
      shippingAddress2: "",
      shippingCity: "",
      shippingState: "",
      shippingZipCode: "",
      buyerSameAsAbove: true,
      buyerFirstName: "",
      buyerLastName: "",
      buyerPhone: "",
      buyerEmail: "",
      total: 0,
      shippingCost: 0,
      totalPrice: 0,
      authorized: "", // boolean
      specialInstructions: "",
      paymentMethod: this.props.user?.preferredPaymentMethod ?? "creditCard", // Enum(creditCard, paperCheck)
      resellerDiscount: 0,
      dataSource: [],
      tokenValue: "",
    };

    this.formRef = null;
  }

  componentDidMount() {
    this.getAllData();
  }

  getAllData = async () => {
    this.setState({ loading: true });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await axios({
        method: "get",
        url: "/products/get",
        headers,
      });

      if (response.statusText === "OK") {
        this.setState({
          dataSource: response.data.store,
          resellerDiscount: response.data.storeDiscount,
        });
      }
    } catch (err) {
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    this.setState({ loading: false });
  };

  onFinish = async (values) => {
    const newState = {
      ...this.state,
      dataSource: this.state.dataSource
        .filter((el) => Number(el.quantity) > 0)
        .map((el) => {
          const { id, quantity } = el;
          return { productId: id, quantity };
        }),
    };

    if (Array.isArray(newState.dataSource) && newState.dataSource.length > 0) {
      const data = { ...values, ...newState };
      Object.keys(data).forEach((el) => {
        if (typeof data[el] === "number") {
          data[el] = data[el].toString();
        }
      });
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
        const response = await axios({
          method: "post",
          url: "/submitStoreOrder",
          headers,
          data: { ...data, userId: this.props.user?.id },
        });

        if (response.statusText === "OK") {
          notification.open({
            message: "Success!",
            description:
              "Your order was submitted successfully! Please make sure you also check your Junk folder in your email and mark our emails as Not Junk.",
            icon: <ExclamationCircleOutlined />,
            style: notificationConfig.style,
          });

          if (this.state.paymentMethod === "creditCard") {
            this.setState({ tokenValue: response.data.token }, () => {
              this.formRef.submit((e) => console.log({ e }));
            });
          }

          this.props.history.push("/po-success");
        }
      } catch (err) {
        notification.open({
          message: "Error",
          description: "We have encountered an error. Please try again!",
          icon: <ExclamationCircleOutlined />,
          style: notificationConfig.style,
        });
      }
    } else {
      notification.open({
        message: "Products missing!",
        description: "Please add a quantity for at least one of the products.",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
  };

  onFinishFailed = (errorInfo) => {
    notification.open({
      message: "Information missing!",
      description: "Please fill in all mandatory fields!",
      icon: <ExclamationCircleOutlined />,
      style: notificationConfig.style,
    });
  };

  handleTableChange = (ev, key, index) => {
    const dataSourceCopy = [...this.state.dataSource];
    // const shippingCost = 0;

    dataSourceCopy[index].quantity = ev;
    const total = dataSourceCopy.reduce(
      (acc, el) => acc + (el.quantity ?? 0) * (Number(el.price) ?? 0),
      0
    );

    const shippingCost = dataSourceCopy.reduce(
      (acc, el) => acc + (el.quantity ?? 0) * (Number(el.shippingCost) ?? 0),
      0
    );

    // const totalPrice = dataSourceCopy.reduce(
    //   (acc, el) =>
    //     acc +
    //     (el.quantity ?? 0) * (Number(el.price) ?? 0 + el.shippingCost ?? 0),
    //   0
    // );

    this.setState({
      dataSource: dataSourceCopy,
      total,
      totalPrice: this.computeTotalPrice(total, shippingCost),
      shippingCost,
    });
  };

  computeTotalPrice = (total, shippingCost) =>
    Number(total) + Number(shippingCost);

  onValuesChange = (values) => {
    this.setState(values);
  };

  getText = () => <span>Shipping costs</span>;

  getContent = () => (
    <div>
      {this.state.dataSource.map((product) => (
        <p key={product.key}>
          {product.name} - ${product.shippingCost} per case
        </p>
      ))}
    </div>
  );

  render() {
    return (
      <>
        <Form
          {...layout}
          name="orderForm"
          initialValues={{ remember: true }}
          onValuesChange={this.onValuesChange}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <h4>Create your Angelchimes USA Purchase Order</h4>
          <br />
          <p>
            Angelchimes USA Customer Number:{" "}
            <span style={{ color: "#c82727" }}>{this.props.user.id}</span>
          </p>
          <p>Store Name: {this.props.user.storeName}</p>
          <p>
            Store Contact: {this.props.user.primaryContactFirstName}{" "}
            {this.props.user.primaryContactLastName}
          </p>
          <p>
            Address: {this.props.user.address},{" "}
            {this.props.user.address2 ? `${this.props.user.address2}, ` : ""}
            {this.props.user.city}, {this.props.user.stateOrProvince},{" "}
            {this.props.user.zipCode}
          </p>
          <p>Email: {this.props.user.primaryContactEmail}</p>
          <p>Phone Number: {this.props.user.primaryContactPhone}</p>
          <br />
          <p>
            <b>Shipping Details</b>
          </p>
          <FormItem
            name="shippingSameAsAbove"
            initialValue={true}
            rules={[
              {
                required: true,
                message: "Please select one option",
              },
            ]}
            validateTrigger="onChange"
          >
            <Radio.Group value={this.state.shippingSameAsAbove}>
              <Radio value={true}>same as above</Radio>
              <Radio value={false}>different</Radio>
            </Radio.Group>
          </FormItem>

          {!this.state.shippingSameAsAbove && (
            <>
              <label>Name</label>
              <FormItem
                name={`shippingName`}
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please input the company name!",
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder={"Company Name"} />
              </FormItem>

              <label>Address</label>
              <FormItem
                name="shippingAddress"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please input the address",
                  },
                ]}
                validateTrigger="onBlur"
                help="Use this field for address street and number."
              >
                <Input placeholder={"Address"} />
              </FormItem>

              <label>Address2</label>
              <FormItem
                name="shippingAddress2"
                initialValue=""
                validateTrigger="onBlur"
                help="If necessary, use this field for apartment number, suite number etc."
              >
                <Input placeholder={"Address"} />
              </FormItem>

              <label>City</label>
              <FormItem
                name="shippingCity"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please input the city",
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder={"City"} />
              </FormItem>

              <label>State</label>
              <FormItem
                name="shippingState"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please input the state",
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Select value={this.state.shippingState}>
                  {Object.keys(states).map((key) => (
                    <Option value={key} key={key}>
                      {states[key]}
                    </Option>
                  ))}
                </Select>
              </FormItem>

              <label>ZIP Code</label>
              <FormItem
                name="shippingZipCode"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please input the ZIP code",
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder={"ZIP Code"} />
              </FormItem>
            </>
          )}

          <label>Buyer Contact for this order:</label>
          <FormItem
            name="buyerSameAsAbove"
            initialValue={true}
            rules={[
              {
                required: true,
                message: "Please select one option",
              },
            ]}
            validateTrigger="onChange"
          >
            <Radio.Group value={this.state.buyerSameAsAbove}>
              <Radio value={true}>same as above</Radio>
              <Radio value={false}>different</Radio>
            </Radio.Group>
          </FormItem>

          {!this.state.buyerSameAsAbove && (
            <>
              <label>First Name</label>
              <FormItem
                name="buyerFirstName"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please input the contact person first name",
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder={"First Name"} />
              </FormItem>

              <label>Last Name</label>
              <FormItem
                name="buyerLastName"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please input the contact person last name",
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder={"Last Name"} />
              </FormItem>
              <label>Phone Number</label>
              <FormItem
                name="buyerPhone"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please input the phone number",
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder={"Phone Number"} />
              </FormItem>
              <label>Email</label>
              <FormItem
                name="buyerEmail"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please input the email address",
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder={"Email"} />
              </FormItem>
            </>
          )}

          <label>Shipping Special Instructions</label>
          <FormItem name="specialInstructions" initialValue="">
            <Input.TextArea placeholder={"Special instructions"} />
          </FormItem>

          <label>
            I am authorized to complete this order on behalf of:{" "}
            {this.props.user.storeName}
          </label>
          <FormItem
            name="authorized"
            initialValue=""
            rules={[
              {
                required: true,
                message: "Please select one option",
              },
            ]}
            validateTrigger="onChange"
          >
            <Radio.Group value={this.state.authorized}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </FormItem>

          <br />
          <p>
            <b>Cart:</b>
          </p>

          {this.state.dataSource.map((el, elId) => (
            <Row
              key={el.id}
              gutter={[16, 0]}
              style={{
                //   border: "0.5px solid #A2C294",
                borderRadius: 15,
                marginBottom: 30,
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
                fontSize: 16,
                color: "#000",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={el.image ? `${el.image}` : ""}
                  alt="product"
                  style={{
                    height: window.innerWidth < 1000 ? 150 : 220,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    padding: window.innerWidth < 1000 ? 10 : 25,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ marginBottom: 10 }}>
                    <b>{el.name}</b>
                    {!el.available ? (
                      <span style={{ color: "#c82727" }}>*</span>
                    ) : null}
                  </p>
                  <p style={{ marginBottom: 4 }}>
                    ${el.price} per case{" "}
                    <span style={{ color: "#c82727" }}>
                      {!el.available ? "*Not Available!" : null}
                    </span>
                  </p>
                  <p style={{ marginBottom: 4, textAlign: "center" }}>
                    {el.description}
                  </p>
                  <InputNumber
                    style={{
                      alignItems: "flex-end",
                      width: "100%",
                      marginTop: 10,
                    }}
                    min={0}
                    placeholder="Quantity"
                    onChange={(e) => this.handleTableChange(e, "0", elId)}
                    value={this.state.dataSource[elId].quantity}
                    disabled={!el.available}
                    key={`${el.id}_input`}
                  />
                </div>
              </div>
            </Row>
          ))}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <br />
            <p style={{ color: "#000", fontSize: 16 }}>
              <b>Order Pricing Summary:</b>
            </p>

            <label style={{ color: "#000" }}>Price (USD)</label>
            <FormItem>
              <InputNumber
                formatter={(value) =>
                  `$ ${Number(value) ? Number(value).toFixed(2) : 0.0}`
                }
                disabled
                value={this.state.total}
                precision={2}
                className="input-disabled"
              />
            </FormItem>

            {/* <label>Discount</label>
          <FormItem>
            <InputNumber
              value={this.state.resellerDiscount}
              formatter={(value) => `${value} %`}
              disabled
              className="input-disabled"
            />
          </FormItem> */}

            <label style={{ color: "#000" }}>Shipping Cost</label>
            <FormItem>
              <InputNumber
                formatter={(value) => `$ ${value}`}
                disabled
                value={this.state.shippingCost}
                className="input-disabled"
              />
            </FormItem>
            <Popover
              placement="top"
              title={this.getText}
              content={this.getContent}
              trigger="click"
            >
              {/* <InfoCircleOutlined
                style={{ marginLeft: 10, color: "#b32a2a" }}
              /> */}
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#b32a2a",
                  borderColor: "#b32a2a",
                }}
              >
                Shipping Costs Details
              </Button>
            </Popover>
            <br />
            <br />

            <label style={{ color: "#000" }}>Total Price (USD)</label>
            <FormItem>
              <InputNumber
                formatter={(value) =>
                  `$ ${Number(value) ? Number(value).toFixed(2) : 0.0}`
                }
                disabled
                value={this.state.totalPrice}
                precision={2}
                // style={{ width: "50%" }}
                className="input-disabled"
              />
            </FormItem>

            <label style={{ color: "#000" }}>Payment Method</label>
            <FormItem
              name="paymentMethod"
              initialValue={this.props.user?.preferredPaymentMethod}
              rules={[
                {
                  required: true,
                  message: "Please select a payment method!",
                },
              ]}
              validateTrigger="onChange"
            >
              <Radio.Group value={this.state.paymentMethod}>
                {/* <Radio.Button value={"digitalCheck"}>Digital Check</Radio.Button> */}
                <Radio.Button value={"creditCard"}>
                  Debit/Credit Card
                </Radio.Button>
                <Radio.Button value={"paperCheck"}>Paper Check</Radio.Button>
              </Radio.Group>
            </FormItem>

            <p>
              When you submit your order, you will immediately receive a
              Purchase Order with payment instructions.
            </p>

            <FormItem>
              <Button
                type="primary"
                // className="btn btn-primary btn-block"
                htmlType="submit"
                style={{ backgroundColor: "#b32a2a", borderColor: "#b32a2a" }}
                disabled={!this.state.authorized || this.state.total === 0}
              >
                Create Purchase Order
              </Button>
            </FormItem>
          </div>

          <br></br>
          <Tagline displayText />
        </Form>
        <form
          method="post"
          action={action}
          id="formAuthorizeNetTestPage"
          name="formAuthorizeNetTestPage"
          ref={(r) => (this.formRef = r)}
        >
          <input type="hidden" name="token" value={this.state.tokenValue} />
        </form>
      </>
    );
  }
}
