import React from "react";
import {
  Form,
  Checkbox,
  Table,
  Popconfirm,
  notification,
  // Divider,
  Button,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import axios from "../../config/api";
import { notificationConfig, action } from "../../config/constants";
import Tagline from "../../components/tagline";

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

export default class OrderList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: {},
      dataSource: [],
      totalPrice: 0,
      selected: [],
      tokenValue: "",
      loading: false,
    };

    this.formRef = null;
  }

  async componentDidMount() {
    this.getAllData();
  }

  getAllData = async () => {
    this.setState({ loading: true });
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "multipart/form-data",
    };
    const params = {
      userId: this.props.user.id,
    };

    await axios
      .get("/purchaseOrdersForUser", { headers, params })
      .then((result) => {
        if (result.statusText === "OK") {
          this.setState({
            dataSource: result.data.storeOrders,
          });
        }
      })
      .catch((err) => {
        notification.open({
          message: "Error!",
          description: "Could not load POs. Please try again!",
          icon: <ExclamationCircleOutlined />,
          style: notificationConfig.style,
        });
      });
    this.setState({ loading: false });
  };

  onFinish = async (values) => {
    // this.setState({ tokenValue: response.data.token }, () => {
    //   this.formRef.submit((e) => console.log({ e }));
    // });
  };

  onFinishFailed = (errorInfo) => {
    notification.open({
      message: "Information missing!",
      description: "Please fill in all mandatory fields!",
      icon: <ExclamationCircleOutlined />,
      style: notificationConfig.style,
    });
  };

  onValuesChange = (values) => {
    this.setState(values);
  };

  handleSubmitPayment = async (id) => {
    this.setState({ loading: true });
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await axios({
        method: "post",
        url: "/submitPayment",
        headers,
        data: { id },
      });

      console.log({ response });

      if (response.statusText === "OK" && response.data.isSuccess === true) {
        this.setState({ tokenValue: response.data.token }, () => {
          this.formRef.submit((e) => console.log({ e }));
        });
      }
    } catch (err) {}
    this.setState({ loading: false });
  };

  handleGetPurchaseOrder = async (orderHash) => {
    this.setState({ loading: true });
    if (!orderHash) {
      notification.open({
        message: "Error!",
        description:
          "We've encoutered an issue opening the Purchase Order. Please try agian later",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
      return;
    }
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/pdf",
      };
      const params = {
        orderHash,
      };

      await axios
        .get("/viewPurchaseOrder", { headers, params, responseType: "blob" })
        .then((response) => {
          const file = new Blob([response.data], {
            type: "application/pdf",
          });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
        });
    } catch (err) {
      console.warn({ err });
      notification.open({
        message: "Error!",
        description:
          "We've encoutered an issue opening the Purchase Order. Please try agian later",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    this.setState({ loading: false });
  };

  render() {
    const columns = [
      {
        dataIndex: "id",
        render: (text, row) => text,
        title: "PO ID",
        sorter: (a, b) => {
          if (a.id > b.id) return -1;
          if (b.id > a.id) return 1;
          return 0;
        },
      },
      {
        dataIndex: "orderHash",
        render: (text) => (
          <Button
            type="primary"
            style={{ backgroundColor: "#b32a2a", borderColor: "#b32a2a" }}
            onClick={() => this.handleGetPurchaseOrder(text)}
          >
            View
          </Button>
        ),
        title: "PDF",
      },
      {
        dataIndex: "createdAt",
        render: (text, row) => {
          const date = new Date(text);
          return date.toLocaleDateString("en-US");
        },
        title: "Date",
        sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      },
      {
        dataIndex: "totalPrice",
        render: (text, row) => `$${text}`,
        title: "Amount",
        sorter: (a, b) => a.totalPrice - b.totalPrice,
      },
      {
        dataIndex: "paymentComplete",
        render: (text, row) => <Checkbox disabled={true} checked={text} />,
        title: "Paid",
        sorter: (a, b) => a.paymentComplete - b.paymentComplete,
        filters: [
          {
            text: "Paid",
            value: true,
          },
          {
            text: "Not Paid",
            value: false,
          },
        ],
        onFilter: (value, record) => record.paymentComplete === value,
      },
      {
        render: (text, row) =>
          row.paymentComplete ? null : (
            <Popconfirm
              title={
                row.paymentMethod !== "creditCard" ? (
                  <>
                    <p>
                      You have selected Digital Check or Paper Check in the
                      Purchase Order process.
                    </p>
                    <p>
                      Instructions for Paper Check and Digital Check payment are
                      in the email accompanying your purchase order.
                    </p>
                    <p>
                      Alternatively, you can continue to direct payment now with
                      Credit/Debit Card by clicking below.
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      You are about to start the payment process for PO ID{" "}
                      {row.id}.
                    </p>
                    <p>Click below to continue.</p>
                  </>
                )
              }
              onConfirm={() => this.handleSubmitPayment(row.id)}
              // onCancel={cancel}
              okText="Credit/Debit Card Payment"
              cancelText="Back"
            >
              <Button
                type="primary"
                style={{ backgroundColor: "#b32a2a", borderColor: "#b32a2a" }}
              >
                Pay Now
              </Button>
            </Popconfirm>
          ),
        title: "",
      },
    ];

    return (
      <>
        <Form
          {...layout}
          name="orderList"
          initialValues={{ remember: true }}
          onValuesChange={this.onValuesChange}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <h4>Purchase Orders</h4>

          <Table
            columns={columns}
            dataSource={this.state.dataSource}
            // onChange={this.handleTableChange}
            pagination={true}
            scroll={{ x: true }}
            loading={this.state.loading}
            rowKey="id"
          />
        </Form>

        <br></br>
        <Tagline />

        <form
          method="post"
          action={action}
          id="formAuthorizeNetTestPage"
          name="formAuthorizeNetTestPage"
          ref={(r) => (this.formRef = r)}
        >
          <input type="hidden" name="token" value={this.state.tokenValue} />
        </form>
      </>
    );
  }
}
