import React, { useEffect } from "react";
import { Input, Table, Button, notification, Popconfirm } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "../../../config/api";
import { notificationConfig, baseUrl } from "../../../config/constants";

function SalesSheetsTab(props) {
  const [loading, setLoading] = React.useState(false);
  const [dataSource, setDataSource] = React.useState([]);
  const [value, setValue] = React.useState("");

  useEffect(() => {
    getSalesSheets();
  }, []);

  const getSalesSheets = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios({
        method: "get",
        url: "/sheet/list",
        headers,
      });

      if (response.data.salesSheets) {
        setDataSource(response.data.salesSheets);
      }
    } catch (e) {
      console.log("Error loading salessheets!");
    }
    setLoading(false);
  };

  const handleSalesSheetDelete = async (sheetId) => {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const headers = { Authorization: `Bearer ${token}` };
      const result = await axios({
        method: "post",
        url: "/sheet/remove",
        data: { sheetId },
        headers,
      });

      if (result.statusText === "OK") {
        notification.open({
          message: "Success!",
          description: "File was deleted successfully!",
          icon: <ExclamationCircleOutlined />,
          style: notificationConfig.style,
        });
        setDataSource(result.data.salesSheets);
      }
    } catch (e) {
      notification.open({
        message: "Error",
        description: "Something went wrong. Please retry to delete the file!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }

    setLoading(false);
  };

  const handleSalesSheetAdd = async (event) => {
    setLoading(true);

    try {
      const sheet = new FormData();
      sheet.append("sheet", event.target.files[0]);
      sheet.append("fileName", event.target.files[0].name);
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      };

      await axios.post("/sheet/add", sheet, { headers }).then((result) => {
        if (result.statusText === "OK") {
          notification.open({
            message: "Success!",
            description: "File upload was completed successfully!",
            icon: <ExclamationCircleOutlined />,
            style: notificationConfig.style,
          });
          setDataSource(result.data.salesSheets);
        }
      });
    } catch (err) {
      notification.open({
        message: "Error!",
        description: "File upload failed. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    setLoading(false);
    setValue("");
  };

  const columns = [
    {
      dataIndex: "name",
      render: (text, row) => (
        <a
          href={`${baseUrl}${row.path}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.fileName}
        </a>
      ),
      title: "Document name",
    },
    {
      dataIndex: "delete",
      render: (text, row) => (
        <Popconfirm
          title={`Are you sure to delete this sales sheet?`}
          onConfirm={() => handleSalesSheetDelete(row.key)}
          // onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link">
            <DeleteOutlined style={{ color: "#c82727" }} />
          </Button>
        </Popconfirm>
      ),
      title: "",
    },
  ];

  return (
    <>
      <h4>Sales sheets</h4>
      <>
        <br />
        <div style={{ display: "flex" }}>
          <Input
            disabled={loading}
            type="file"
            onChange={handleSalesSheetAdd}
            value={value}
          />
        </div>
      </>
      <br />
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        loading={loading}
        scroll={{ x: true }}
      />
    </>
  );
}

export default SalesSheetsTab;
