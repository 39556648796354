import React from "react";
import { Tabs } from "antd";

import SalesSheetsTab from "./tabs/salesSheetsTab";
import StoresTab from "./tabs/storesTab";
import PurchaseOrdersTab from "./tabs/purchaseOrdersTab";
import ProductsTab from "./tabs/productsTab";

import "./index.css";
import Tagline from "../../components/tagline";

const { TabPane } = Tabs;

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      tabKey: 1,
    };
  }

  handleTabChange = (tabKey) => {
    this.setState({ tabKey });
  };

  render() {
    return (
      <>
        <Tabs
          defaultActiveKey={this.state.tabKey}
          onChange={this.handleTabChange}
        >
          <TabPane tab={"Stores"} key={1}>
            <StoresTab />
          </TabPane>
          <TabPane tab={"Sales Sheets"} key={2}>
            <SalesSheetsTab user={this.props.user} />
          </TabPane>
          <TabPane tab={"Purchase orders"} key={3}>
            <PurchaseOrdersTab />
          </TabPane>
          <TabPane tab={"Product Management"} key={4}>
            <ProductsTab />
          </TabPane>
        </Tabs>
        <br></br>

        <Tagline />
      </>
    );
  }
}
