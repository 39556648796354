import React from "react";
import { Form, Input, Button, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import axios from "../../config/api";
import { notificationConfig } from "../../config/constants";

const FormItem = Form.Item;

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
  },
};

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
    };
  }

  onValuesChange = (value) => {
    this.setState(value);
  };

  onFinish = async (values) => {
    const data = { ...values, email: values.email.toLowerCase() };
    try {
      const response = await axios({
        method: "post",
        url: "/forgotpassword",
        data,
      });
      if (response.statusText === "OK") {
        this.props.history.push("/login");
        notification.open({
          message: "Success!",
          description: "A password recovery email was sent!",
          icon: <ExclamationCircleOutlined />,
          style: notificationConfig.style,
        });
      }
    } catch (e) {
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  handleValidateEmail = (rule, value, callback) => {
    if (!value) callback("Please input your email!");
    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!reg.test(this.state.email.toLowerCase()))
      callback("Please insert a valid email address");
    else callback();
  };

  render() {
    return (
      <Form
        {...layout}
        name="forgotPasswordForm"
        initialValues={{ remember: true }}
        onValuesChange={this.onValuesChange}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
      >
        <label>Email address</label>
        <FormItem
          name="email"
          initialValue=""
          rules={[
            {
              required: true,
              validator: this.handleValidateEmail,
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input placeholder={"Email address"} />
        </FormItem>

        <FormItem>
          <Button
            type="primary"
            style={{
              borderColor: "#b32a2a",
              backgroundColor: "#b32a2a",
            }}
            // className="btn btn-primary btn-block"
            htmlType="submit"
          >
            Submit
          </Button>
        </FormItem>
      </Form>
    );
  }
}
