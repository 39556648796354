import React, { useEffect, useState } from "react";
import {
  Radio,
  Button,
  Table,
  Divider,
  Input,
  InputNumber,
  notification,
  Popconfirm,
} from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "../../../config/api";
import { notificationConfig } from "../../../config/constants";
import TextArea from "antd/lib/input/TextArea";

function ProductsTab() {
  const [loading, setLoading] = useState(false);
  // const [storeDiscount, setStoreDiscount] = useState(30);
  // const [customerDiscount, setCustomerDiscount] = useState(30);
  const [storeProducts, setStoreProducts] = useState([]);
  //   {
  //     key: "appleFreezeDriedFruit",
  //     id: 1,
  //     name: "Apple Freeze Dried Fruit",
  //     type: "Single Pac",
  //     price: 2,
  //     description: ".4 ounces/11 grams",
  //     available: true,
  //   },
  // ]);
  const [customerProducts, setCustomerProducts] = useState([]);
  //   {
  //     key: "appleFreezeDriedFruit",
  //     id: 1,
  //     name: "Apple Freeze Dried Fruit",
  //     type: "Multi Pac",
  //     price: 10,
  //     description: "contains 6 single pacs .4 ounces/11 grams",
  //     available: true,
  //   },
  // ]);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await axios({
        method: "get",
        url: "/products/get",
        headers,
      });

      if (response.statusText === "OK") {
        const a = response.data.store.map((el) => {
          el.sku = el.key;
          return el;
        });
        const b = response.data.customer.map((el) => {
          el.sku = el.key;
          return el;
        });
        setStoreProducts(a);
        setCustomerProducts(b);
        // setStoreDiscount(response.data.storeDiscount);
        // setCustomerDiscount(response.data.customerDiscount);
      }
    } catch (err) {
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    setLoading(false);
  };

  const handleProductImageDelete = async (event, row, index, type) => {
    setLoading(true);
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    const params = { productId: row.id };
    await axios
      .delete("/productImage/remove", { headers, params })
      .then((response) => {
        if (response.statusText === "OK") {
          notification.open({
            message: "Success!",
            description: "The image has been successfully deleted!",
            icon: <ExclamationCircleOutlined />,
            style: notificationConfig.style,
          });

          type === "customer"
            ? setCustomerProducts((prev) => {
                const newData = [...prev];
                newData[index]["image"] = null;

                return newData;
              })
            : setStoreProducts((prev) => {
                const newData = [...prev];
                newData[index]["image"] = null;

                return newData;
              });
        }
      })
      .catch((err) => {
        notification.open({
          message: "Error",
          description: "We have encountered an error. Please try again!",
          icon: <ExclamationCircleOutlined />,
          style: notificationConfig.style,
        });
      });
    setLoading(false);
  };

  const handleAddProductImage = async (event, row, index, type) => {
    setLoading(true);

    try {
      const image = new FormData();
      image.append("image", event.target.files[0]);
      image.append("fileName", event.target.files[0].name);
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      };
      const params = {
        productId: row.id,
      };

      await axios
        .post("/productImage/add", image, { headers, params })
        .then((result) => {
          if (result.statusText === "OK") {
            notification.open({
              message: "Success!",
              description: "File upload was completed successfully!",
              icon: <ExclamationCircleOutlined />,
              style: notificationConfig.style,
            });

            type === "customer"
              ? setCustomerProducts((prev) => {
                  const newData = [...prev];
                  newData[index]["image"] = result.data.imagePath.replace(
                    "https://api.angelchimesusa.com",
                    ""
                  );

                  return newData;
                })
              : setStoreProducts((prev) => {
                  const newData = [...prev];
                  newData[index]["image"] = result.data.imagePath.replace(
                    "https://api.angelchimesusa.com",
                    ""
                  );

                  return newData;
                });
          }
        });
    } catch (err) {
      notification.open({
        message: "Error!",
        description: "File upload failed. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    setLoading(false);
    // setValue("");
  };

  // type is 'store' or 'customer'
  const handleProductDelete = async (id, type) => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const data = { productId: id };
      const response = await axios({
        method: "delete",
        url: "/products/remove",
        headers,
        data,
      });

      if (response.statusText === "OK") {
        getAllData();
        notification.open({
          message: "Success!",
          description: "The product has been successfully deleted!",
          icon: <ExclamationCircleOutlined />,
          style: notificationConfig.style,
        });
      }
    } catch (err) {
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    setLoading(false);
  };

  const handleSaveProducts = async (source, type, template) => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const data = {
        customer:
          source === "auto" && type === "customer"
            ? [
                ...customerProducts.map((el) => {
                  el.key = el.sku;
                  return el;
                }),
                template,
              ]
            : customerProducts.map((el) => {
                el.key = el.sku;
                return el;
              }),
        store:
          source === "auto" && type === "store"
            ? [
                ...storeProducts.map((el) => {
                  el.key = el.sku;
                  return el;
                }),
                template,
              ]
            : storeProducts.map((el) => {
                el.key = el.sku;
                return el;
              }),
        // storeDiscount,
        // customerDiscount,
      };
      const response = await axios({
        method: "post",
        url: "/products/add",
        headers,
        data,
      });

      if (response.statusText === "OK") {
        notification.open({
          message: "Success!",
          description: "Your products have been successfully saved!",
          icon: <ExclamationCircleOutlined />,
          style: notificationConfig.style,
        });
      }
    } catch (err) {
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    setLoading(false);
  };

  const handleAddProduct = async (type) => {
    try {
      const productTemplate = {
        name: null,
        type: null,
        price: null,
        description: null,
        available: false,
      };

      await handleSaveProducts("auto", type, productTemplate);
      await getAllData();
    } catch (err) {
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
  };

  const handleTableChange = (e, row, attr, type) => {
    const value =
      attr === "available" ||
      attr === "description" ||
      attr === "name" ||
      attr === "sku"
        ? e.target.value
        : e;

    type === "customer"
      ? setCustomerProducts((prev) => {
          const newData = [...prev];
          newData[row][attr] = value;

          return newData;
        })
      : setStoreProducts((prev) => {
          const newData = [...prev];
          newData[row][attr] = value;

          return newData;
        });
  };

  const storeColumns = [
    {
      dataIndex: "name",
      render: (text, row, index) => (
        <TextArea
          style={{ width: 250 }}
          value={text}
          placeholder={"Name"}
          onChange={(e) => handleTableChange(e, index, "name", "store")}
        />
      ),
      title: "Product Name",
      width: 250,
    },
    {
      dataIndex: "sku",
      render: (text, row, index) => (
        <TextArea
          style={{ width: 150 }}
          value={text}
          placeholder={"SKU"}
          onChange={(e) => handleTableChange(e, index, "sku", "store")}
          key={row.name}
        />
      ),
      title: "SKU",
      width: 150,
    },
    // {
    //   dataIndex: "type",
    //   render: (text, row, index) => (
    //     <Select
    //       value={text}
    //       onChange={(e) => handleTableChange(e, index, "type", "store")}
    //     >
    //       <Option value="singlePac">Single Pac</Option>
    //       <Option value="multiPac">Multi Pac</Option>
    //     </Select>
    //   ),
    //   title: "Type",
    // },
    {
      dataIndex: "price",
      render: (text, row, index) => (
        <InputNumber
          style={{ width: 75 }}
          formatter={(value) => `$ ${value}`}
          value={text}
          onChange={(e) => handleTableChange(e, index, "price", "store")}
        />
      ),
      title: "Case Price",
      width: 75,
    },
    {
      dataIndex: "shippingCost",
      render: (text, row, index) => (
        <InputNumber
          style={{ width: 60 }}
          formatter={(value) => `$ ${value}`}
          value={text}
          onChange={(e) => handleTableChange(e, index, "shippingCost", "store")}
        />
      ),
      title: "Shipping Cost",
      width: 60,
    },
    {
      dataIndex: "description",
      render: (text, row, index) => (
        <TextArea
          style={{ width: 250 }}
          value={text}
          placeholder={"Description"}
          onChange={(e) => handleTableChange(e, index, "description", "store")}
        />
      ),
      title: "Description",
      width: 250,
    },
    {
      dataIndex: "available",
      render: (text, row, index) => (
        <Radio.Group
          value={text}
          onChange={(e) => handleTableChange(e, index, "available", "store")}
        >
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      ),
      title: "Available",
    },
    {
      dataIndex: "image",
      render: (text, row, index) =>
        !text ? (
          <Input
            // style={{ width: 100 }}
            disabled={loading}
            type="file"
            onChange={(e) => handleAddProductImage(e, row, index, "store")}
          />
        ) : (
          <>
            <img src={`${text}`} alt="product" style={{ width: 50 }} />
            <br />
            <Button
              type="link"
              onClick={(e) => handleProductImageDelete(e, row, index, "store")}
              style={{ paddingLeft: 0 }}
            >
              Remove
            </Button>
          </>
        ),
      title: "Image",
      // width: 150,
    },
    {
      render: (text, row) => (
        <Popconfirm
          title={`Are you sure to delete this product?`}
          onConfirm={() => handleProductDelete(row.id, "store")}
          // onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button
            // onClick=
            type="link"
            loading={loading}
          >
            <DeleteOutlined style={{ color: "#c82727" }} />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const customerColumns = [
    {
      dataIndex: "name",
      render: (text, row, index) => (
        <TextArea
          style={{ width: 250 }}
          value={text}
          placeholder={"Name"}
          onChange={(e) => handleTableChange(e, index, "name", "customer")}
        />
      ),
      title: "Product Name",
      width: 250,
    },
    {
      dataIndex: "sku",
      render: (text, row, index) => (
        <TextArea
          style={{ width: 150 }}
          value={text}
          placeholder={"SKU"}
          onChange={(e) => handleTableChange(e, index, "sku", "customer")}
        />
      ),
      title: "SKU",
      width: 150,
    },
    // {
    //   dataIndex: "type",
    //   render: (text, row, index) => (
    //     <Select
    //       value={text}
    //       onChange={(e) => handleTableChange(e, index, "type", "customer")}
    //     >
    //       <Option value="singlePac">Single Pac</Option>
    //       <Option value="multiPac">Multi Pac</Option>
    //     </Select>
    //   ),
    //   title: "Type",
    // },
    {
      dataIndex: "price",
      render: (text, row, index) => (
        <InputNumber
          style={{ width: 75 }}
          formatter={(value) => `$ ${value}`}
          value={text}
          onChange={(e) => handleTableChange(e, index, "price", "customer")}
        />
      ),
      title: "Unit Price",
      width: 75,
    },
    {
      dataIndex: "shippingCost",
      render: (text, row, index) => (
        <InputNumber
          style={{ width: 60 }}
          formatter={(value) => `$ ${value}`}
          value={text}
          onChange={(e) =>
            handleTableChange(e, index, "shippingCost", "customer")
          }
        />
      ),
      title: "Shipping Cost",
      width: 60,
    },
    {
      dataIndex: "description",
      render: (text, row, index) => (
        <TextArea
          style={{ width: 250 }}
          value={text}
          placeholder={"Description"}
          onChange={(e) =>
            handleTableChange(e, index, "description", "customer")
          }
        />
      ),
      title: "Description",
      width: 250,
    },
    {
      dataIndex: "available",
      render: (text, row, index) => (
        <Radio.Group
          value={text}
          onChange={(e) => handleTableChange(e, index, "available", "customer")}
        >
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      ),
      title: "Available",
    },
    // {
    //   dataIndex: "grouping",
    //   render: (text, row, index) => (
    //     <InputNumber
    //       value={text}
    //       onChange={(e) => handleTableChange(e, index, "grouping", "customer")}
    //     />
    //   ),
    //   title: "Row Grouping",
    // },
    {
      dataIndex: "image",
      render: (text, row, index) =>
        !text ? (
          <Input
            disabled={loading}
            type="file"
            onChange={(e) => handleAddProductImage(e, row, index, "customer")}
          />
        ) : (
          <>
            <img src={`${text}`} alt="product" style={{ width: 50 }} />
            <br />
            <Button
              type="link"
              onClick={(e) =>
                handleProductImageDelete(e, row, index, "customer")
              }
              style={{ paddingLeft: 0 }}
            >
              Remove
            </Button>
          </>
        ),
      title: "Image",
    },
    {
      render: (text, row) => (
        <Popconfirm
          title={`Are you sure to delete this product?`}
          onConfirm={() => handleProductDelete(row.id, "customer")}
          // onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button
            // onClick=
            type="link"
            loading={loading}
          >
            <DeleteOutlined style={{ color: "#c82727" }} />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <p>
        <b>Wholesale Store</b>
      </p>
      <Table
        columns={storeColumns}
        dataSource={storeProducts}
        pagination={false}
        scroll={{ x: true }}
        rowKey={(el) => el.key}
      />
      <br />
      <Button
        onClick={() => handleAddProduct("store")}
        style={{
          backgroundColor: "#b32a2a",
          borderColor: "#b32a2a",
          color: "#fff",
        }}
        loading={loading}
      >
        Add product
      </Button>
      <br />
      <br />
      {/* <label>Store products discount</label>
      <br />
      <InputNumber
        formatter={(value) => `${value} %`}
        value={storeDiscount}
        onChange={(e) => setStoreDiscount(e)}
      /> */}
      <Divider />

      <p>
        <b>Consumer Store</b>
      </p>
      <Table
        columns={customerColumns}
        dataSource={customerProducts}
        pagination={false}
        scroll={{ x: true }}
        rowKey={(el) => el.key}
      />
      <br />
      <Button
        onClick={() => handleAddProduct("customer")}
        style={{
          backgroundColor: "#b32a2a",
          borderColor: "#b32a2a",
          color: "#fff",
        }}
        loading={loading}
      >
        Add product
      </Button>
      <br />
      <br />
      <Divider />

      <Button
        onClick={handleSaveProducts}
        style={{
          backgroundColor: "#b32a2a",
          borderColor: "#b32a2a",
          color: "#fff",
          float: "right",
        }}
        loading={loading}
      >
        Save
      </Button>
    </>
  );
}

export default ProductsTab;
