import React from "react";

const OrderSuccess = () => {
  return (
    <>
      <h4>Success!</h4>
      <br />
      <br />
      <p>
        Your have successfully paid for your products. Your invoice will arrive
        in a few minutes.
      </p>
      <p>Thank you for being part of our symphony of Tradition since 1948!</p>
    </>
  );
};

export default OrderSuccess;
