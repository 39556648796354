import React, { Component } from "react";
import { Form, Input, Button, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import "../../App.css";
import axios from "../../config/api";
import { notificationConfig } from "../../config/constants";

const FormItem = Form.Item;

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  handleValidateEmail = (rule, value, callback) => {
    if (!value) callback("Please input your email!");
    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!reg.test(this.state.email.toLowerCase()))
      callback("Please insert a valid email address");
    else callback();
  };

  handleValidatePassword = (rule, value, callback) => {
    if (!value) callback("Please input your password!");
    if (value.length < 6)
      callback("Please insert a password with at least 6 characters");
    else {
      callback();
    }
  };

  onFinish = async (values) => {
    this.setState({ loading: true });
    const data = { ...values, email: values.email.toLowerCase() };
    try {
      const response = await axios({
        method: "post",
        url: "/login",
        data,
      });

      if (response.data.user && !response.data?.user?.approved) {
        notification.open({
          message: "Error",
          description: "Your account has not yet been approved!",
          icon: <ExclamationCircleOutlined />,
          style: notificationConfig.style,
        });
      } else if (response.data.token) {
        this.props.handleLogin(response);
        this.props.history.push("/");
      }
    } catch (e) {
      notification.open({
        message: "Error",
        description:
          "Something went wrong. Please check your email and password and try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    this.setState({ loading: false });
    window.dispatchEvent(new Event("popstate"));
  };

  onFinishFailed = (errorInfo) => {
    notification.open({
      message: "Error",
      description: "Please insert a valid email and password!",
      icon: <ExclamationCircleOutlined />,
      style: notificationConfig.style,
    });
  };

  render() {
    return (
      <Form
        {...layout}
        name="signupForm"
        initialValues={{ remember: true }}
        onValuesChange={this.onValuesChange}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
      >
        <h3>Sign In</h3>

        <label>Email address</label>
        <FormItem
          name="email"
          initialValue=""
          rules={[
            {
              required: true,
              // validator: this.handleValidateEmail,
              message: "Please insert an email address",
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input placeholder={"Email address"} disabled={this.state.loading} />
        </FormItem>

        <label>Password</label>
        <FormItem
          name="password"
          initialValue=""
          rules={[
            {
              required: true,
              message: "Please insert a password",
              // validator: this.handleValidatePassword,
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input.Password
            placeholder={"Password"}
            disabled={this.state.loading}
          />
        </FormItem>
        <p className="forgot-password text-right">
          <Link to="/forgotpassword">Forgot password?</Link>
        </p>

        <FormItem>
          <Button
            type="primary"
            // className="btn btn-primary btn-block"
            style={{
              backgroundColor: "#b32a2a",
              borderColor: "#b32a2a",
              color: "#fff",
            }}
            htmlType="submit"
            disabled={this.state.loading}
          >
            Login
          </Button>
        </FormItem>
        <p>
          Experiencing issues? Notify us at{" "}
          <a href="mailto:partners@angelchimesusa.com">
            partners@angelchimesusa.com
          </a>
          !
        </p>
      </Form>
    );
  }
}
