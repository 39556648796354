import React from "react";
import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Radio,
  notification,
  Divider,
} from "antd";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";

import axios from "../../config/api";
import { notificationConfig, states } from "../../config/constants";
import "../../App.css";
import Tagline from "../../components/tagline";

const FormItem = Form.Item;
const { Option } = Select;

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

class UserSignup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      storeName: "",
      companyName: "",
      address: "",
      address2: "",
      city: "",
      stateOrProvince: "",
      zipCode: "",
      website: "",
      instagram: "",
      email: "",
      password: "",
      confirmPassword: "",
      primaryContactFirstName: "", //displayed on order page
      primaryContactLastName: "", //displayed on order page
      primaryContactEmail: "",
      primaryContactPhone: "",
      secondaryContactFullName: "",
      secondaryContactEmail: "",
      secondaryContactPhone: "",
      storeType: "",
      role: "",
      storeDescription: "",
      howManyLocations: "",
      preferredPaymentMethod: null,
      agreeToSell: false,
      agreeToRepresent: false,
      loading: false,
    };
  }

  onFinish = async (values) => {
    this.setState({ loading: true });
    const data = { ...this.state, email: this.state.email.toLowerCase() };

    try {
      const response = await axios({
        method: "post",
        url: "/signup",
        data,
      });
      if (response.data.token) {
        // this.props.handleLogin(response);
        notification.open({
          message: "Success!",
          description: (
            <>
              <p>
                Your registration was successful. You will receive an email once
                you are approved and you will be able to login on this page.
              </p>
              <p>
                <b>
                  <i>
                    Please make sure you also check your Junk folder in your
                    email and mark our emails as Not Junk.
                  </i>
                </b>
              </p>
            </>
          ),
          icon: <ExclamationCircleOutlined />,
          style: notificationConfig.style,
          duration: 0,
        });
        this.props.history.push("/");
      }
    } catch (e) {
      notification.open({
        message: "Error",
        description:
          "The email address is already taken. Please try a different one!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }

    this.setState({ loading: false });
  };

  onFinishFailed = (errorInfo) => {
    notification.open({
      message: "Error",
      description: "Please fill in all fields!",
      icon: <ExclamationCircleOutlined />,
      style: notificationConfig.style,
    });
  };

  onValuesChange = (values) => {
    this.setState(values);
  };

  handleValidateEmail = (rule, value, callback) => {
    if (!value) callback("Please input your email!");
    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!reg.test(this.state.email.toLowerCase()))
      callback("Please insert a valid email address");
    else callback();
  };

  handleValidateEmailIfInsert = (rule, value, callback) => {
    if (!value) callback();

    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!reg.test(this.state.email.toLowerCase()))
      callback("Please insert a valid email address");
  };

  handleValidatePassword = (rule, value, callback) => {
    if (!value) callback("Please input your password!");
    if (value.length < 6)
      callback("Please insert a password with at least 6 characters");
    else {
      callback();
    }
  };

  handlePasswordRepeat = (rule, value, callback) => {
    if (!value) callback("Please repeat your password");
    if (value && value !== this.state.password) {
      callback("Passwords do not match.");
    } else {
      callback();
    }
  };

  handlePositiveField = (rule, value, callback) => {
    if (value === "" || value === null || value === undefined)
      callback("Please select an answer!");
    if (value === false)
      callback("You must answer 'Yes' to this question in order to sign up");
    else callback();
  };

  render() {
    return (
      <Form
        {...layout}
        name="signupForm"
        initialValues={{ remember: true }}
        onValuesChange={this.onValuesChange}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        form={this.formRef}
      >
        <h4>Signup Form</h4>
        <label>Store Name</label>
        <FormItem
          name="storeName"
          initialValue=""
          rules={[{ required: true, message: "Please input your store name!" }]}
          validateTrigger="onBlur"
        >
          <Input placeholder={"Store Name"} />
        </FormItem>

        <label>Company Name</label>
        <FormItem
          name="companyName"
          initialValue=""
          rules={[{ required: true, message: "Please input your store name!" }]}
          validateTrigger="onBlur"
        >
          <Input placeholder={"Store Name"} />
        </FormItem>

        <label>Street Address</label>
        <FormItem
          name="address"
          initialValue=""
          rules={[{ required: true, message: "Please input your address!" }]}
          validateTrigger="onBlur"
          help="Use this field for your address street and number"
        >
          <Input placeholder={"Address"} />
        </FormItem>
        <label>Address 2</label>
        <FormItem
          name="address2"
          initialValue=""
          validateTrigger="onBlur"
          help="If necessary, use this field for apartment number, suite number etc."
        >
          <Input placeholder={"Address 2"} />
        </FormItem>

        <label>City</label>
        <FormItem
          name="city"
          initialValue=""
          rules={[{ required: true, message: "Please input your city!" }]}
          validateTrigger="onBlur"
        >
          <Input placeholder={"City"} />
        </FormItem>

        <label>State or Province</label>
        <FormItem
          name="stateOrProvince"
          initialValue=""
          rules={[
            { required: true, message: "Please input your state or province!" },
          ]}
          validateTrigger="onBlur"
        >
          <Select value={this.state.stateOrProvince}>
            {Object.keys(states).map((key) => (
              <Option value={key} key={key}>
                {states[key]}
              </Option>
            ))}
          </Select>
        </FormItem>

        <label>ZIP Code</label>
        <FormItem
          name="zipCode"
          initialValue=""
          rules={[{ required: true, message: "Please input your ZIP code!" }]}
          validateTrigger="onBlur"
        >
          <Input placeholder={"ZIP Code"} />
        </FormItem>

        <label>Website</label>
        <FormItem name="website" initialValue="" validateTrigger="onBlur">
          <Input placeholder={"Website"} />
        </FormItem>

        <label>Instagram</label>
        <FormItem name="instagram" initialValue="" validateTrigger="onBlur">
          <Input placeholder={"Instagram profile"} />
        </FormItem>

        <Divider />

        <label>Email address (Account Username)</label>
        <FormItem
          name="email"
          initialValue=""
          rules={[{ required: true, validator: this.handleValidateEmail }]}
          validateTrigger="onBlur"
          help="Please retain your username email and password"
        >
          <Input placeholder={"Email address"} />
        </FormItem>

        <label>Password</label>
        <FormItem
          name="password"
          initialValue=""
          rules={[
            {
              required: true,
              validator: this.handleValidatePassword,
            },
          ]}
          validateTrigger="onBlur"
          help="Please retain your username email and password"
        >
          <Input.Password placeholder={"Password"} />
        </FormItem>

        <label>Confirm Password</label>
        <FormItem
          name="confirmPassword"
          initialValue=""
          rules={[
            {
              required: true,
              validator: this.handlePasswordRepeat,
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input.Password placeholder={"Password"} />
        </FormItem>

        <Divider />

        <label>Primary Contact</label>
        <br />
        <label>First Name</label>
        <FormItem
          name="primaryContactFirstName"
          initialValue=""
          rules={[
            {
              required: true,
              message: "Please input your primary contact first name!",
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input placeholder={"First Name"} />
        </FormItem>

        <label>Last Name</label>
        <FormItem
          name="primaryContactLastName"
          initialValue=""
          rules={[
            {
              required: true,
              message: "Please input your primary contact last name!",
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input placeholder={"Last Name"} />
        </FormItem>
        <label>Email</label>
        <FormItem
          name="primaryContactEmail"
          initialValue=""
          rules={[{ required: true, validator: this.handleValidateEmail }]}
          validateTrigger="onBlur"
        >
          <Input placeholder={"Email address"} />
        </FormItem>

        <label>Phone number</label>
        <FormItem
          name="primaryContactPhone"
          initialValue=""
          rules={[
            { required: true, message: "Please input your phone number!" },
          ]}
          validateTrigger="onBlur"
        >
          <Input placeholder={"Phone number"} />
        </FormItem>

        <Divider />

        <label>Secondary Contact</label>
        <br />
        <label>Full Name</label>
        <FormItem
          name="secondaryContactFullName"
          initialValue=""
          validateTrigger="onBlur"
        >
          <Input placeholder={"Full Name"} />
        </FormItem>
        <label>Email</label>
        <FormItem
          name="secondaryContactEmail"
          initialValue=""
          rules={[
            { required: false, validator: this.handleValidateEmailIfInsert },
          ]}
          validateTrigger="onBlur"
        >
          <Input placeholder={"Email address"} />
        </FormItem>

        <label>Phone number</label>
        <FormItem
          name="secondaryContactPhone"
          initialValue=""
          validateTrigger="onBlur"
        >
          <Input placeholder={"Phone number"} />
        </FormItem>

        <Divider />

        <label>Type of store</label>
        <FormItem
          name="storeType"
          initialValue=""
          rules={[{ required: true, message: "Please input your store type!" }]}
          validateTrigger="onBlur"
        >
          <Select value={this.state.companyType}>
            <Option value="localStore">Local Store</Option>
            <Option value="regionalRetailChain">Regional Retail Chain</Option>
            <Option value="nationalRetailChain">National Retail Chain</Option>
            <Option value="other">
              Other (association, community organization)
            </Option>
          </Select>
        </FormItem>

        <label>How many locations do you have?</label>
        <FormItem
          name="howManyLocations"
          initialValue=""
          rules={[
            {
              required: true,
              message: "Please input the number of locations!",
            },
          ]}
          validateTrigger="onBlur"
        >
          <InputNumber placeholder={"Number of locations"} />
        </FormItem>

        <label>What is your role in your organization?</label>
        <FormItem
          name="role"
          initialValue=""
          rules={[{ required: true, message: "Please input your role!" }]}
          validateTrigger="onBlur"
        >
          <Input placeholder="Role" />
        </FormItem>

        <label>
          Please tell us about your store and how we can best work with you:
        </label>
        <FormItem
          name="storeDescription"
          initialValue=""
          rules={[
            {
              required: true,
              message: "Please input your store description!",
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input.TextArea placeholder={"Store Description"} />
        </FormItem>

        <label>What is your preferred payment method?</label>
        <FormItem
          name="preferredPaymentMethod"
          initialValue=""
          rules={[
            {
              required: true,
              message: "Please select a preferred payment method!",
            },
          ]}
          validateTrigger="onChange"
        >
          <Radio.Group value={this.state.preferredPaymentMethod}>
            {/* <Radio.Button value="digitalCheck">Digital Check</Radio.Button> */}
            <Radio.Button value="paperCheck">Paper Check</Radio.Button>
            <Radio.Button value="creditCard">Credit/Debit Card</Radio.Button>
          </Radio.Group>
        </FormItem>

        <Divider />

        <label>
          Do you agree to sell Angel Chimes USA Products only at your store(s),
          your events and your company/store website and not online with third
          party websites such as Amazon?
        </label>
        <FormItem
          name="agreeToSell"
          initialValue=""
          rules={[
            {
              required: true,
              validator: this.handlePositiveField,
            },
          ]}
          validateTrigger="onChange"
        >
          <Radio.Group value={this.state.agreeToSell}>
            <Radio.Button value={true}>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
          </Radio.Group>
        </FormItem>

        <label>
          Do you agree to make your best effort to represent the Angel Chimes
          USA brand with integrity and communicate any issues or questions to us
          in a timely manner?
        </label>
        <FormItem
          name="agreeToRepresent"
          initialValue=""
          rules={[
            {
              required: true,
              validator: this.handlePositiveField,
            },
          ]}
          validateTrigger="onChange"
        >
          <Radio.Group value={this.state.agreeOtherThanWebsite}>
            <Radio.Button value={true}>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
          </Radio.Group>
        </FormItem>

        <br />
        <p>
          <b>
            <i>
              *** Please make sure you also check your Junk folder in your email
              and mark our emails as Not Junk.
            </i>
          </b>
        </p>
        <FormItem>
          <Button
            disabled={!(this.state.agreeToRepresent && this.state.agreeToSell)}
            type="primary"
            htmlType="submit"
            style={{ borderColor: "#b32a2a", backgroundColor: "#b32a2a" }}
            loading={this.state.loading}
            onClick={this.onFinish}
          >
            Sign Up
          </Button>
        </FormItem>
        <p className="forgot-password text-right">
          Already registered?{" "}
          <Link to={"/login"}>
            <Button
              onClick={() => window.dispatchEvent(new Event("popstate"))}
              type="link"
              style={{
                color: "#b32a2a",
              }}
            >
              Login
            </Button>
          </Link>
        </p>
        <div
          style={{
            marginTop: 15,
            marginBottom: 15,
            borderTopColor: "#c82727",
            borderTopWidth: 1,
            borderTopStyle: "solid",
            height: 5,
          }}
        />

        <Tagline />
      </Form>
    );
  }
}

export default UserSignup;
