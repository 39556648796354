import React from "react";
import { Form, Input, Radio, notification, Button, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { action } from "../../config/constants";

import axios from "../../config/api";
import { notificationConfig, states } from "../../config/constants";

const FormItem = Form.Item;
const { Option } = Select;

const layout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

export default class CustomerOrderForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      email: "",
      phone: "",
      shippingSameAsAbove: true,
      shippingBuyerFirstName: "",
      shippingBuyerLastName: "",
      shippingAddress: "",
      shippingAddress2: "",
      shippingCity: "",
      shippingState: "",
      shippingZipCode: "",
      specialInstructions: "",
      tokenValue: "",
    };

    this.formRef = null;
  }

  handleValidateEmail = (rule, value, callback) => {
    if (!value) callback("Please input your email!");
    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!reg.test(this.state.email.toLowerCase()))
      callback("Please insert a valid email address");
    else callback();
  };

  onFinish = async (values) => {
    this.setState({ loading: true });
    const data = { ...this.props.stepOneData, ...this.state };
    try {
      const response = await axios({
        method: "post",
        url: "/submitCustomerOrder",
        data,
      });

      if (response.statusText === "OK" && response.data.isSuccess === true) {
        notification.open({
          message: "Success!",
          description: "Your order was submitted successfully!",
          icon: <ExclamationCircleOutlined />,
          style: notificationConfig.style,
        });

        this.setState({ tokenValue: response.data.token }, () => {
          this.formRef.submit((e) => console.log({ e }));
        });

        this.props.next();
      }
    } catch (err) {
      notification.open({
        message: "Error",
        description: "We have encountered an error. Please try again!",
        icon: <ExclamationCircleOutlined />,
        style: notificationConfig.style,
      });
    }
    this.setState({ loading: false });
  };

  onFinishFailed = (errorInfo) => {
    notification.open({
      message: "Information missing!",
      description: "Please fill in all mandatory fields!",
      icon: <ExclamationCircleOutlined />,
      style: notificationConfig.style,
    });
  };

  onValuesChange = (values) => {
    this.setState(values);
  };

  render() {
    return (
      <>
        <Form
          {...layout}
          name="orderForm"
          initialValues={{ remember: true }}
          onValuesChange={this.onValuesChange}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
        >
          <label>First Name</label>
          <FormItem
            name={`firstName`}
            initialValue=""
            rules={[
              {
                required: true,
                message: "Please input your first name!",
              },
            ]}
            validateTrigger="onBlur"
          >
            <Input placeholder={"First Name"} />
          </FormItem>

          <label>Last Name</label>
          <FormItem
            name={`lastName`}
            initialValue=""
            rules={[
              {
                required: true,
                message: "Please input your last name!",
              },
            ]}
            validateTrigger="onBlur"
          >
            <Input placeholder={"Last Name"} />
          </FormItem>

          <label>Address</label>
          <FormItem
            name="address"
            initialValue=""
            rules={[
              {
                required: true,
                message: "Please input the address",
              },
            ]}
            validateTrigger="onBlur"
            help="Use this field for your address street and number"
          >
            <Input placeholder={"Address"} />
          </FormItem>

          <label>Address 2</label>
          <FormItem
            name="address2"
            initialValue=""
            validateTrigger="onBlur"
            help="If necessary, use this field for apartment number, suite number etc."
          >
            <Input placeholder={"Address 2"} />
          </FormItem>

          <label>City</label>
          <FormItem
            name="city"
            initialValue=""
            rules={[
              {
                required: true,
                message: "Please input the city",
              },
            ]}
            validateTrigger="onBlur"
          >
            <Input placeholder={"City"} />
          </FormItem>

          <label>State</label>
          <FormItem
            name="state"
            initialValue=""
            rules={[
              {
                required: true,
                message: "Please input the state",
              },
            ]}
            validateTrigger="onBlur"
          >
            <Select value={this.state.state}>
              {Object.keys(states).map((key) => (
                <Option value={key} key={key}>
                  {states[key]}
                </Option>
              ))}
            </Select>
          </FormItem>

          <label>ZIP Code</label>
          <FormItem
            name="zipCode"
            initialValue=""
            rules={[
              {
                required: true,
                message: "Please input the ZIP code",
              },
            ]}
            validateTrigger="onBlur"
          >
            <Input placeholder={"ZIP Code"} />
          </FormItem>

          <label>Email address</label>
          <FormItem
            name="email"
            initialValue=""
            rules={[{ required: true, validator: this.handleValidateEmail }]}
            validateTrigger="onBlur"
          >
            <Input placeholder={"Email address"} />
          </FormItem>

          <label>Phone number</label>
          <FormItem
            name="phone"
            initialValue=""
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
            validateTrigger="onBlur"
          >
            <Input placeholder={"Phone number"} />
          </FormItem>
          <br />

          <label>Shipping Details:</label>
          <FormItem
            name="shippingSameAsAbove"
            initialValue=""
            rules={[
              {
                required: true,
                message: "Please select one option",
              },
            ]}
            validateTrigger="onChange"
          >
            <Radio.Group value={this.state.shippingSameAsAbove}>
              <Radio value={true}>same as above</Radio>
              <Radio value={false}>different</Radio>
            </Radio.Group>
          </FormItem>

          {!this.state.shippingSameAsAbove && (
            <>
              <label>First Name</label>
              <FormItem
                name={`shippingBuyerFirstName`}
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder={"First Name"} />
              </FormItem>

              <label>Last Name</label>
              <FormItem
                name={`shippingBuyerLastName`}
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder={"Last Name"} />
              </FormItem>

              <label>Address</label>
              <FormItem
                name="shippingAddress"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please input the address",
                  },
                ]}
                validateTrigger="onBlur"
                help="Use this field for your address street and number."
              >
                <Input placeholder={"Address"} />
              </FormItem>

              <label>Address 2</label>
              <FormItem
                name="shippingAddress2"
                initialValue=""
                validateTrigger="onBlur"
                help="If necessary, use this field for apartment number, suite number etc."
              >
                <Input placeholder={"Address 2"} />
              </FormItem>

              <label>City</label>
              <FormItem
                name="shippingCity"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please input the city",
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder={"City"} />
              </FormItem>

              <label>State</label>
              <FormItem
                name="shippingState"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please input the state",
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Select value={this.state.shippingState}>
                  {Object.keys(states).map((key) => (
                    <Option value={key} key={key}>
                      {states[key]}
                    </Option>
                  ))}
                </Select>
              </FormItem>

              <label>ZIP Code</label>
              <FormItem
                name="shippingZipCode"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please input the ZIP code",
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder={"ZIP Code"} />
              </FormItem>
            </>
          )}

          <label>Shipping Special Instructions</label>
          <FormItem name="specialInstructions" initialValue="">
            <Input.TextArea placeholder={"Special instructions"} />
          </FormItem>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                margin: "0 8px",
                borderColor: "#b32a2a",
                color: "#b32a2a",
              }}
              onClick={this.props.prev}
              loading={this.state.loading}
            >
              Back
            </Button>
            <FormItem>
              <Button
                style={{
                  backgroundColor: "#b32a2a",
                  color: "#fff",
                  borderColor: "#b32a2a",
                }}
                type="primary"
                htmlType="submit"
                loading={this.state.loading}
              >
                Checkout
              </Button>
            </FormItem>
          </div>
        </Form>
        <form
          method="post"
          action={action}
          id="formAuthorizeNetTestPage"
          name="formAuthorizeNetTestPage"
          ref={(r) => (this.formRef = r)}
        >
          <input type="hidden" name="token" value={this.state.tokenValue} />
        </form>
      </>
    );
  }
}
